import { RELATED_ITEMS_BOOTSTRAP } from 'constants/Actions';

import { parseRelatedItemEntry } from 'store/models/EntryParser';

const bootstrap = (state, items) => {
  /**
   * strip out any empty block items
   */
  const blockItems = items.reduce((acc, obj) => {
    const isEmpty = Object.keys(obj).length === 0;
    if (!isEmpty) {
      acc.push(obj);
    }
    return acc;
  }, []);
  /**
   * parse remaining
   */
  const entries = blockItems.map((item, i) => {
    const content_type = item.content_type === 'web_game_html5' ? 'web_game' : item.content_type;
    return parseRelatedItemEntry(content_type, item);
  });
  return {
    type: RELATED_ITEMS_BOOTSTRAP,
    entries,
  };
};

export const bootstrapRelatedItems = (items) => {
  return (dispatch, getState) => {
    return dispatch(bootstrap(getState(), items));
  };
};
