import { getSelectedFilterId } from 'store/filters/filtersSelectors';
import { DEFAULT_ENTRY_QUERY_LIMIT, PLATFORMS_LOOKUP } from 'constants/App';

export const getIsInitialised = (state) => {
  return state.entries.initialised;
};

export const getPageHasNoEntriesToRequest = (state) => {
  return state.entries.hasNoEntriesToRequest;
};

export const getIsFetching = (state) => {
  return state.entries.isFetching;
};

export const getIsFetchQueued = (state) => {
  return state.entries.isFetchQueued;
};

export const getShouldTrackPageView = (state) => {
  return state.entries.trackPageView;
};

export const getIsFetchingContentCount = (state) => {
  return state.entries.isFetchingContentCount;
};

export const getIsReady = (state) => {
  return state.entries.isReady;
};

export const getDidError = (state) => {
  return state.entries.didInvalidate;
};

export const getSelectedEntryID = (state) => {
  return state.selectedEntryID;
};

export const getIsFetchingContentData = (state) => {
  return state.entries.isFetching;
};

const getCategoryItemsByFilterId = (state, filterId) => {
  return state.entries.categories.byID[filterId] && state.entries.categories.byID[filterId].items ? state.entries.categories.byID[filterId].items : [];
};

const getTotalCountByFilterId = (state, filterId) => {
  const category = state.entries.categories.byID[filterId];
  if (!category) {
    return 0; // DEFAULT_ENTRY_QUERY_LIMIT + 1;
  }
  const { offset, limit, total_count } = category;
  return offset < limit ? offset : total_count;
};

const getEntriesCountByFilterId = (state, filterId) => {
  return getCategoryItemsByFilterId(state, filterId).length;
};

export const getLimitsByFilterId = (state) => {
  const filterId = getSelectedFilterId(state);
  const cat = state.entries.categories.byID[filterId] || { offset: 0, limit: DEFAULT_ENTRY_QUERY_LIMIT };
  return { offset: cat.offset, limit: cat.limit };
};

export const getHasEntries = (state) => {
  const filterId = getSelectedFilterId(state);
  return getEntriesCountByFilterId(state, filterId) > 0;
};

export const getMoreEntriesAvailable = (state) => {
  const filterId = getSelectedFilterId(state);
  const entriesCountByFilterId = getEntriesCountByFilterId(state, filterId);
  const totalCountByFilterId = getTotalCountByFilterId(state, filterId);
  // console.log(`%cgetMoreEntriesAvailable | entries ${entriesCountByFilterId} | total ${totalCountByFilterId}`, 'color: purple;');
  return entriesCountByFilterId < totalCountByFilterId;
};

export const getEntries = (state) => {
  const filterId = getSelectedFilterId(state);
  let refs = getCategoryItemsByFilterId(state, filterId);
  // console.log('getEntries', filterId, refs);
  if (PLATFORMS_LOOKUP.includes(filterId)) {
    return refs.map((id) => {
      const platformId = id + '_' + filterId;
      return state.entries.apps.byID[platformId];
    });
  } else {
    return refs.map((id) => {
      return state.entries.items.byID[id];
    });
  }
};

export const getTotalEntriesCount = (state) => {
  const totalCount = state.entries.totalCount;
  return totalCount > DEFAULT_ENTRY_QUERY_LIMIT ? DEFAULT_ENTRY_QUERY_LIMIT : totalCount;
};

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];
