import React, { Component } from 'react';
import { connect } from 'react-redux';

import RelatedItemsCarouselRow from 'components/relatedItems/RelatedItemsCarouselRow';
import RelatedItemsCarouselRowNoSlider from 'components/relatedItems/RelatedItemsCarouselRowNoSlider';
import { getAllCopy } from 'store/copy/copySelectors';
import { getCarouselBreakpoints } from 'store/app/appSelectors';

class RelatedItemsBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isSmartphone, title, blocks, wrapperClass, ageRating, isRTL } = this.props; // passed through
    const { copy, carouselBreakpoints } = this.props;
    const wrapperStyle = {};

    const carouselOptions = {
      isRTL,
      copy,
      title,
      blocks,
      ageRating,
      carouselBreakpoints,
      wrapperClass,
      wrapperStyle,
    };

    return blocks.length > 0 && !isSmartphone ? <RelatedItemsCarouselRow {...carouselOptions} /> : blocks.length > 0 ? <RelatedItemsCarouselRowNoSlider {...carouselOptions} /> : null;
  }
}

function mapStateToProps(state) {
  return {
    copy: getAllCopy(state),
    carouselBreakpoints: getCarouselBreakpoints(state),
  };
}

export default connect(mapStateToProps, null)(RelatedItemsBlock);
