import React from 'react';
import { LazyImageWithPlaceholder } from 'components/LazyImageWithPlaceholder';
import Ribbon from 'components/Ribbon';
import { track } from 'constants/EnsightenTracking';
import { LOG } from 'constants/App';

const CarouselItemOther = (props) => {
  const { isNewLabel, ageRating, content_type, title, description, alt_tag, titleAttribute, iconAlt, isNewContent, icon, link, target } = props;
  const { img_small, img_medium, img_large, img_placeholder, img_width, img_height, isFallbackImage } = props;
  const { amc_store, amc_show, amc_title, amc_redirect, amc_exit_track } = props;
  const { carouselItemStyle = {}, carouselItemClass = '' } = props;
  const imageData = {
    small: img_small,
    medium: img_medium,
    large: img_large,
    placeholder: img_placeholder,
    width: img_width,
    height: img_height,
  };

  LOG.enabled && console.log(`%cCarouselItemOther | ${isFallbackImage ? 'fallback' : '16:9'} : ${title}`, isFallbackImage ? 'background: pink; color: black' : 'background: #666; color: white');

  const triggerEnsightenTracking = () => {
    track(props);
  };

  let a_clazz = `content_item content_item--${content_type} ready_to_animate animate`;

  const RenderImage = isFallbackImage ? (
    <LazyImageWithPlaceholder alt={alt_tag} imageData={imageData} />
  ) : (
    <div className="content_item_inner__image">
      <LazyImageWithPlaceholder alt={alt_tag} imageData={imageData} />
    </div>
  );

  return (
    <div className={carouselItemClass} style={carouselItemStyle}>
      <a
        className={a_clazz}
        href={link}
        target={target}
        title={titleAttribute || title}
        data-amcstore={amc_store}
        data-amcshow={amc_show}
        data-amctitle={amc_title}
        data-amcredirect={amc_redirect}
        data-amcexittrack={amc_exit_track}
        onClick={triggerEnsightenTracking}
        rel={target === '_blank' ? 'noopener' : ''}
      >
        <span className="content_item_inner">
          {RenderImage}
          <span className="content_item_label label">
            <img className="content_item__icon" src={icon && icon} alt={iconAlt} />

            {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

            <h3>{title}</h3>
            <p>{description}</p>
          </span>
          {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
        </span>
      </a>
    </div>
  );
};
export default CarouselItemOther;
