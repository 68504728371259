import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBottomBlocks, getDidError, getIsReady, getTopBlocks } from 'store/carousels/carouselsSelectors';
import { getAllCopy } from 'store/copy/copySelectors';
import { fetchCarousels, parseBlocks } from 'store/carousels/carouselsActions';
import CarouselBlock from 'components/carousel/CarouselBlock';
import FeatureBlock from 'components/feature/FeatureBlock';
import CarouselError from 'components/carousel/CarouselError';
import { getBlockItems } from 'store/feature/featureSelectors';
import { LOG } from 'constants/App';

class CarouselsWithFeature extends Component {
  constructor(props) {
    super(props);
    const placeholderBlocks = parseBlocks(props.contentBlocks);
    this.placeholderBlocksTop = placeholderBlocks.slice(0, 2);
    this.placeholderBlocksBottom = placeholderBlocks.slice(2);
    this.state = { initialised: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.initialised && this.props.hasBeenVisible) {
      LOG.enabled && console.log(`%cCarouselsWithFeature.componentDidUpdate`, LOG.clrBlack);
      this.setState({ initialised: true });
      this.props.fetchCarousels();
    }
  }

  render() {
    const { siteStyles, ageRating, isSmartphone, rightPadStyle, spinner, carouselPlaceholder, isRTL } = this.props; // passed through
    const { isReady, isError, copy, topBlocks, bottomBlocks, featureBlocks } = this.props;

    const carouselOptions = {
      isReady,
      copy,
      siteStyles,
      isSmartphone,
      ageRating,
      rightPadStyle,
      spinner,
      isRTL,
    };

    if (isError) {
      return <CarouselError />;
    } else {
      return (
        <>
          <CarouselBlock blocks={topBlocks} {...carouselOptions} />
          <FeatureBlock copy={copy} blocks={featureBlocks} siteStyles={siteStyles} ageRating={ageRating} />
          <CarouselBlock blocks={bottomBlocks} {...carouselOptions} />
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    copy: getAllCopy(state),
    isReady: getIsReady(state),
    isError: getDidError(state),
    topBlocks: getTopBlocks(state),
    bottomBlocks: getBottomBlocks(state),
    featureBlocks: getBlockItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCarousels: () => {
      dispatch(fetchCarousels());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarouselsWithFeature);
