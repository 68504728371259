import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import SwiperCore, { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import CarouselItemOther from 'components/carousel/CarouselItemOther';
import CarouselItemApp from 'components/carousel/CarouselItemApp';
import { Title } from 'components/carousel/Title';
import { USE_WEBP } from 'constants/App';

SwiperCore.use([Navigation, Virtual]);

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

class RelatedItemsCarouselRow extends Component {
  constructor(props) {
    super(props);
    this.swiper = React.createRef();
  }

  renderItems({ blocks, isNewLabel, ageRating, carouselItemStyle }) {
    let blockCount = 0;
    return blocks.map((data, index) => {
      blockCount++;
      if (data.platforms) {
        return (
          <SwiperSlide key={data.id} virtualIndex={index}>
            <CarouselItemApp isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass="swiper-slide" carouselItemStyle={carouselItemStyle} />
          </SwiperSlide>
        );
      } else {
        return (
          <SwiperSlide key={data.id} virtualIndex={index}>
            <CarouselItemOther isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass="swiper-slide" carouselItemStyle={carouselItemStyle} />
          </SwiperSlide>
        );
      }
    });
  }

  render() {
    const CAROUSEL_BREAKPOINT = 601;
    const { isRTL, copy, title, blocks, ageRating, carouselBreakpoints, wrapperClass, wrapperStyle } = this.props; // passed through
    const { home_new_content_title: isNewLabel } = copy;
    const hasWrapper = wrapperClass !== null;
    const carouselItemStyle = {};

    const params = {
      spaceBetween: 36,
      slidesPerView: 3,
      loop: false,
      navigation: {
        prevEl: '.swiper_button_prev',
        nextEl: '.swiper_button_next',
        disabledClass: 'disabled',
      },
    };
    if (isRTL) {
      params.dir = 'rtl';
    }

    const extraClass = USE_WEBP ? 'webp' : 'non-webp';

    const carouselItems = this.renderItems({ blocks, isNewLabel, ageRating, carouselItemStyle });
    const blockCount = carouselItems.length;
    const carouselDisplayCount = window.innerWidth > CAROUSEL_BREAKPOINT ? 3 : 1; // adjust this for mobile
    const hasNavButtons = blockCount > carouselDisplayCount;

    const portalNode = document.getElementById('related-items');
    const els =
      !blockCount > 0 || !portalNode ? null : (
        <ConditionalWrapper
          condition={hasWrapper}
          wrapper={(children) => (
            <div className={wrapperClass} style={wrapperStyle}>
              {children}
            </div>
          )}
        >
          {<Title ribbon={false} title={title} extraClass={extraClass} />}
          <div className="carousel_new related_items">
            <Swiper ref={this.swiper} virtual {...params} breakpoints={carouselBreakpoints}>
              {carouselItems}
              {hasNavButtons && (
                <>
                  <div className="swiper_btn swiper_button_prev" />
                  <div className="swiper_btn swiper_button_next" />
                </>
              )}
            </Swiper>
          </div>
        </ConditionalWrapper>
      );
    return els ? ReactDOM.createPortal(els, portalNode) : null;
  }
}

export default RelatedItemsCarouselRow;
