import request from 'superagent/lib/client';
import {
  API_GET_CONTENT_BLOCKS,
  API_GET_CONTENT_TOTALS,
  API_GET_PUBLISHED_CONTENT,
  CAROUSEL_LIMIT,
  DEFAULT_ENTRY_QUERY_LIMIT,
  DEVICE,
  SHOW_ID,
  SHOW_SUBPAGE_ID,
  getCarouselPageID,
  LOG,
} from 'constants/App';
import { CONTENT_TYPE, CONTENT_TYPE_LOOKUP, PAGE, SUB_PAGE } from 'constants/Const';

/**
 * Jollybase API
 */
export const fetchContentCountService = () => {
  const query = SHOW_ID ? `${API_GET_CONTENT_TOTALS}/show/${SHOW_ID}/` : API_GET_CONTENT_TOTALS;

  const dump = { _query: query };
  LOG.enabledStore && console.log('%c++++++ fetchContentCountService', LOG.clrGreen, dump);

  return request
    .post(query)
    .then((response) => {
      const api_response = JSON.parse(response.text);
      if (api_response.result === 'SUCCESS') {
        LOG.enabledStore && console.log('%c++++++ fetchContentCountService response', LOG.clrGreen, api_response);
        return { totals: api_response.results, total_count: api_response.total_count };
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const fetchCarouselService = (pageId) => {
  const page = getCarouselPageID(pageId);
  const limit = CAROUSEL_LIMIT; // shows 3 on mobile
  const query = API_GET_CONTENT_BLOCKS.replace('{page}', page).replace('{limit}', limit);

  const dump = { pageId, page, limit, _query: query };
  LOG.enabledStore && console.log('%c++++++ fetchCarouselService', LOG.clrGreen, dump);

  return request
    .post(query)
    .then((response) => {
      const api_response = JSON.parse(response.text);
      if (api_response.result === 'SUCCESS') {
        return { blocks: api_response.results };
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
};

const fetchShowEntries = (offset = 0, limit = DEFAULT_ENTRY_QUERY_LIMIT, filterId, filter) => {
  let query = `${API_GET_PUBLISHED_CONTENT}/${CONTENT_TYPE_LOOKUP[filterId]}/show/${SHOW_ID}/offset/${offset}/limit/${limit}`;

  const dump = { filterId, filter, limit, _query: query };
  LOG.enabledStore && console.log('%c++++++ fetchShowEntries', LOG.clrGreen, dump);

  return request
    .post(query)
    .then((response) => {
      const api_response = JSON.parse(response.text);
      if (api_response.result === 'SUCCESS') {
        return { total_count: api_response.total_count, entries: api_response.results };
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
};

const fetchPageEntries = ({ pageId, base, gamesBase, offset = 0, limit = DEFAULT_ENTRY_QUERY_LIMIT, filterId, filter, activeCategoryId }) => {
  const query = addFilter(base, gamesBase, filterId, filter, offset, limit);
  const dump = { pageId, filter, filterId, offset, _base: base, _gamesBase: gamesBase, _query: query };
  activeCategoryId && (dump.activeCategoryId = activeCategoryId);
  LOG.enabledStore && console.log('%c++++++ fetchPageEntries', LOG.clrGreen, dump);

  return request
    .post(query)
    .then((response) => {
      const api_response = JSON.parse(response.text);
      if (api_response.result === 'SUCCESS') {
        return { total_count: api_response.total_count, entries: api_response.results };
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const fetchEntriesService = ({ pageId, offset = 0, limit = DEFAULT_ENTRY_QUERY_LIMIT, filterId, filter, activeCategoryId }) => {
  const isGameCategoryPage = pageId === SUB_PAGE.WEB_GAME_CATEGORY;
  if (pageId === PAGE.SHOWS) {
    if (SHOW_SUBPAGE_ID !== '') {
      const contentType = CONTENT_TYPE_LOOKUP[SHOW_SUBPAGE_ID];
      let base = `${API_GET_PUBLISHED_CONTENT}/${contentType}/show/${SHOW_ID}/`;
      let gamesBase = `${API_GET_PUBLISHED_CONTENT}/${CONTENT_TYPE.APP_AND_WEB_GAME}/show/${SHOW_ID}/`;
      base = pageId === PAGE.GAMES ? `${base}device_type/${DEVICE}/` : base;
      gamesBase = filterId === 'web' ? `${gamesBase}device_type/${DEVICE}/` : gamesBase;
      return fetchPageEntries({ pageId, base, gamesBase, offset, limit, filterId, filter, activeCategoryId: false });
    } else {
      return fetchShowEntries(offset, limit, filterId, filter);
    }
  } else {
    const contentType = isGameCategoryPage ? CONTENT_TYPE_LOOKUP[SUB_PAGE.WEB_GAME_CATEGORY] : CONTENT_TYPE_LOOKUP[pageId];
    let base = `${API_GET_PUBLISHED_CONTENT}/${contentType}/`;
    let gamesBase = isGameCategoryPage ? `${API_GET_PUBLISHED_CONTENT}/${CONTENT_TYPE.WEB_GAME}/` : `${API_GET_PUBLISHED_CONTENT}/${CONTENT_TYPE.APP_AND_WEB_GAME}/`;
    base = pageId === PAGE.GAMES ? `${base}device_type/${DEVICE}/` : base;
    if (isGameCategoryPage) {
      base = `${base}web_game_category/${activeCategoryId}/`;
    } else {
      gamesBase = filterId === 'web' ? `${gamesBase}device_type/${DEVICE}/` : gamesBase;
    }
    return fetchPageEntries({ pageId, base, gamesBase, offset, limit, filterId, filter, activeCategoryId });
  }
};

const addFilter = (base, gamesBase, filterId, filter, offset, limit) => {
  let query;
  switch (filterId) {
    case 'allnew':
    case 'a-z':
    case 'z-a':
      query = `${base}order_by/${filter.by}/order_dir/${filter.direction}/offset/${offset}/limit/${limit}`;
      break;
    case 'personality':
    case 'points':
    case 'poll':
      query = `${base}quiz_type/${filter.type}/offset/${offset}/limit/${limit}`;
      break;
    case 'ios':
    case 'android':
    case 'web':
      query = `${gamesBase}platform/${filter.type}/offset/${offset}/limit/${limit}`;
      break;
  }
  return query;
};
