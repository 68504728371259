import React, { Component } from 'react';
import Dropdown from 'react-dropdown';

export default class FilterDrop extends Component {
  constructor(props) {
    super(props);
    this.onHandleSelect = this.onSelect.bind(this);
  }

  onSelect(event) {
    this.props.handleFilterSelect(event.value);
  }

  render() {
    const { pageId, filterOptions, filterSortLabel, selectedFilterId } = this.props;
    const filterClass = `sort sort--${pageId}`;
    const selectedFilter = filterOptions[0];
    return <Dropdown className={filterClass} options={filterOptions} onChange={this.onHandleSelect} value={selectedFilter} placeholder={filterSortLabel} />;
  }
}
