import { getisGamePage } from 'store/app/appSelectors';
import { FRIENDLY_URLS } from 'constants/App';

export const getActiveCategoryId = (state) => {
  return state.category.activeCategoryId;
};

export const getSelectedSubCategoryId = (state) => {
  return state.category.selectedSubCategoryId;
};

export const getSelectedSubCategoryApiId = (state) => {
  const categoryId = getActiveCategoryId(state);
  const subCategoryId = getSelectedSubCategoryId(state);
  const apiId = state.category.allCategorysById?.[categoryId]?.items?.[subCategoryId]?.apiId;
  // LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ getSelectedSubCategoryApiId :: ${categoryId}, ${subCategoryId}, apiId = ${apiId}`, LOG.clrGreen);
  return apiId;
};

export const getActiveCategoryIds = (state) => {
  const categoryId = getActiveCategoryId(state);
  return state.category.allCategorysById?.[categoryId]?.ids ?? [];
};

export const getActiveCategoryItems = (state) => {
  const isGamePage = getisGamePage(state);
  const selectedSubCategoryId = getSelectedSubCategoryId(state);
  const categoryId = getActiveCategoryId(state);
  /*
   * Returns active category items eg for games category
   * Adjusts friendlyURL to be:
   *  - From Games : `games/category/${friendlyURL}`
   *  - From Game Category : `${friendlyURL}`
   */

  const baseURL = isGamePage ? `${FRIENDLY_URLS.game}/${FRIENDLY_URLS.category}/` : '';
  const ids = state.category.allCategorysById?.[categoryId]?.ids ?? [];
  const items = state.category.allCategorysById?.[categoryId]?.items ?? {};
  return ids.reduce((acc, id) => {
    const adj = { ...items[id] };
    if (id === 'featured') {
      adj.selected = isGamePage;
    } else {
      adj.friendlyURL = baseURL + adj.friendlyURL;
      adj.selected = id === selectedSubCategoryId;
    }
    acc[id] = adj;
    return acc;
  }, {});
};

export const getCategoryInteractionTrackingData = (state, nextSubCategoryId) => {
  const categoryId = getActiveCategoryId(state);
  const subCategoryId = getSelectedSubCategoryId(state);
  // LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ getCategoryInteractionTrackingData :: ${categoryId}, current ${subCategoryId}, next ${nextSubCategoryId}`, LOG.clrGreen);
  if (subCategoryId) {
    const item = state.category.allCategorysById?.[categoryId]?.items?.[subCategoryId] ?? null;
    const subCategory = item ? item.englishname || item.categoryLabel : '';
    const next = state.category.allCategorysById?.[categoryId]?.items?.[nextSubCategoryId] ?? false;
    const nextSubCategory = next ? next.englishname || next.categoryLabel : '';
    return { category: categoryId, subCategory, nextSubCategory };
  }
  return false;
};

export const getCategoryPageLoadTrackingData = (state) => {
  const categoryId = getActiveCategoryId(state);
  const subCategoryId = getSelectedSubCategoryId(state);
  if (subCategoryId) {
    const item = state.category.allCategorysById?.[categoryId]?.items?.[subCategoryId] ?? null;
    const subCategory = item ? item.englishname || item.categoryLabel : '';
    return { category: categoryId, subCategory };
  }
  return false;
};

export const getActiveCategoryContentBlockTitle = (state) => {
  const categoryId = getActiveCategoryId(state);
  const subCategoryId = getSelectedSubCategoryId(state);
  let blockTitle = 'Missing Content Block Title';
  if (subCategoryId) {
    blockTitle = state.category.allCategorysById?.[categoryId]?.items?.[subCategoryId]?.blockTitle ?? 'Missing Content Block Title';
  }
  return blockTitle;
};

export const getSubCategoryIdFromFriendlyURL = (state, pathname) => {
  /*
   * Retrieve subCategoryId via firendlyURL lookup
   */
  const friendlyURL = pathname.includes('/') ? pathname.split('/').pop() : pathname;
  const categoryId = getActiveCategoryId(state);
  const ids = state.category.allCategorysById?.[categoryId]?.ids ?? [];
  const items = state.category.allCategorysById?.[categoryId]?.items ?? [];
  const subCategoryId = ids.filter((id) => {
    return items[id].friendlyURL === friendlyURL;
  });
  return subCategoryId?.[0] || null;
};
