import { getDevice, getPlatform } from 'utils/deviceDetection';
import { canUseWebP } from 'utils/general';
import { PAGE, SUB_PAGE } from 'constants/Const';

export const LOG = {
  enabled: true,
  enabledStore: true,
  clrBlack: 'background: black; color: white',
  clrGreen: 'background: green; color: white',
};
const CONFIG_DATA = window.gallery_config || {};
export const SITE_NAME = CONFIG_DATA?.siteName.toLowerCase();
export const IS_BOOMERANG = SITE_NAME === 'boomerang';
export const IS_CARTOONITO = SITE_NAME === 'cartoonito';
export const IS_BOINGTV = SITE_NAME === 'boingtv';
export const IS_TOONAMI = SITE_NAME === 'toonami';

export const WEBP_ENABLED = CONFIG_DATA.webpEnabled;
export const USE_WEBP = WEBP_ENABLED ? canUseWebP() : false;

const direction = document.dir !== undefined ? document.dir : document.getElementsByTagName('html')[0].getAttribute('dir');
export const IS_RTL = direction === 'rtl';

export const DEVICE = CONFIG_DATA.device || getDevice();
export const CAROUSEL_LIMIT = DEVICE === 'smartphone' ? 3 : 0;

export const LOCALE = CONFIG_DATA.locale;
export const NEW_STYLE = CONFIG_DATA.new_style;
export const API_BASE = CONFIG_DATA.api_url;
export const STATIC_URL = CONFIG_DATA.static_url;
export const DYNAMIC_URL = CONFIG_DATA.dynamic_url;
export const FRONTEND_URL = CONFIG_DATA.frontend_url;
export const DOM_ID = CONFIG_DATA.domId;
export const PAGE_ID = CONFIG_DATA.pageId;
export const SHOW_ID = CONFIG_DATA.showId;
export const SHOW_NAME = CONFIG_DATA.showName;
export const SHOW_TITLE = CONFIG_DATA.showTitle || '';
export const CBTITLES = CONFIG_DATA.cbtitles || '';
export const SHOW_SUBPAGE_ID = CONFIG_DATA.showSubPageId;
export const HUB_ID = CONFIG_DATA.hubId;
export const DEFAULT_ENTRY_QUERY_LIMIT = CONFIG_DATA.entry_page_count;
export const RENDER_AGE_RATING = CONFIG_DATA.renderAgeRating === true;
export const ICON_AGE_RATING = STATIC_URL + `base/images/icons/br_livre.svg`;
export const RENDER_FEATURE_BLOCK = CONFIG_DATA.renderFeatureBlock === true;
export const FILTERS_ACTIVE = CONFIG_DATA.filters_active;
export const FILTER_OPTIONS = CONFIG_DATA.filterOptions;
export const LANGUAGE_TEXTS = CONFIG_DATA.language_texts || {};

export const HAS_CAROUSELS = CONFIG_DATA?.config?.hasCarousels || false;
export const HAS_CONTENT_BLOCK = CONFIG_DATA?.config?.hasFilters || false;

export const FRIENDLY_URLS = CONFIG_DATA.friendlyURLS;
export const PLATFORMS = CONFIG_DATA.platforms || { other: 'other', amazon: 'fire', android: 'android', windowsPhone: 'windows', ios: 'ios', blackberry: 'blackberry' };

const pageData = CONFIG_DATA.pageData || {};
export const PAGE_DATA = pageData.page_data;
export const ADS_DATA = pageData.ads || {};
export const CONTENT_BLOCKS = pageData.content_blocks || [];
export const FEATURE_POSITION = page_data.page_data.featured_pos || '';
export const FEATURE_POSITION_IN_CAROUSELS = FEATURE_POSITION === 'after_second_editorial';
const featured_left = page_data.featured_left || {};
const featured_center = page_data.featured_center || {};
const featured_right = page_data.featured_right || {};
export const FEATURE_BLOCK = [featured_left, featured_center, featured_right];

/**
 * RELATED ITEMS
 * Related content block that sits under slider
 * These were using the old CarouselWidget
 */
export const RELATED_ITEMS = Array.isArray(pageData?.relatedItems) ? pageData.relatedItems : []; // check is an actual array

/**
 * GAME CATEGORY PAGE DATA
 */
const CATEGORIES_ENABLED = CONFIG_DATA.categoriesEnabled;
const CATEGORY_DATA_ALL = CONFIG_DATA.allCategoryPages;
const CATEGORY_DATA_PAGE = window?.gameCategory ?? false;
const IS_GAME_PAGE = PAGE_ID === PAGE.GAMES;
const IS_GAME_CATEGORY_PAGE = PAGE_ID === SUB_PAGE.WEB_GAME_CATEGORY && CATEGORY_DATA_PAGE !== false;
export const CATEGORY_DATA = {
  categoryDataAll: CATEGORY_DATA_ALL,
  categoryDataPage: CATEGORY_DATA_PAGE,
  isGamePage: IS_GAME_PAGE,
  isGameCategoryPage: IS_GAME_CATEGORY_PAGE,
  hasActiveCategorys: CATEGORIES_ENABLED && (IS_GAME_PAGE || IS_GAME_CATEGORY_PAGE),
};

/**
 * 16:9 IMAGES
 */
export const USE_16_9_THUMBNAILS = PAGE_ID === PAGE.GAMES || PAGE_ID === PAGE.VIDEOS || PAGE_ID === SUB_PAGE.GAMEPLAY || PAGE_ID === SUB_PAGE.VIDEOPLAYER;

/**
 * API ENDPOINTS
 */
export const API_GET_CONTENT_BLOCKS = API_BASE + `get_content_blocks/${LOCALE}/{page}/device_type/${DEVICE}/limit/{limit}`;
export const API_GET_PUBLISHED_CONTENT = API_BASE + `get_published_content/${LOCALE}`;
export const API_GET_CONTENT_TOTALS = API_BASE + `count_show_content/${LOCALE}`;

/**
 * PLATFORMS
 */
export const USER_PLATFORM = getPlatform(PLATFORMS);
export const PLATFORM_OTHER = PLATFORMS.other;
export const PLATFORM_IOS = PLATFORMS.ios;
export const PLATFORM_ANDROID = PLATFORMS.android;
export const PLATFORM_AMAZON = PLATFORMS.amazon;
export const PLATFORM_BLACKBERRY = PLATFORMS.blackberry;
export const PLATFORM_WINDOWSPHONE = PLATFORMS.windowsPhone;
export const PLATFORMS_LOOKUP = PLATFORM_IOS + ',' + PLATFORM_ANDROID + ',' + PLATFORM_AMAZON + ',' + PLATFORM_BLACKBERRY + ',' + PLATFORM_WINDOWSPHONE;

/**
 * ALT INFO
 */
const {
  age_rating_attribute_title = 'age rating',
  content_icon_attribute_title = 'content icon',
  placeholder_attribute_title = 'placeholder image',
  content_icon_star_attribute_title = 'content icon',
  content_icon_video_attribute_title = 'content icon',
  content_icon_comic_attribute_title = 'content icon',
  content_icon_game_attribute_title = 'content icon',
  content_icon_activitiy_attribute_title = 'content icon',
  content_icon_app_attribute_title = 'content icon',
  content_icon_character_attribute_title = 'content icon',
  content_icon_download_attribute_title = 'content icon',
  content_icon_campaign_attribute_title = 'content icon',
  content_icon_quiz_attribute_title = 'content icon',
} = LANGUAGE_TEXTS;
export const ICON_ALTS = {
  AGE: age_rating_attribute_title,
  CONTENT_ICON: content_icon_attribute_title,
  PLACEHOLDER: placeholder_attribute_title,
  GAME: content_icon_game_attribute_title,
  STAR: content_icon_star_attribute_title,
  VIDEO: content_icon_video_attribute_title,
  COMIC: content_icon_comic_attribute_title,
  ACTIVITIY: content_icon_activitiy_attribute_title,
  APP: content_icon_app_attribute_title,
  CHARACTER: content_icon_character_attribute_title,
  DOWNLOAD: content_icon_download_attribute_title,
  CAMPAIGN: content_icon_campaign_attribute_title,
  QUIZ: content_icon_quiz_attribute_title,
};

/**
 * Site Specific Styles:
 * unify the ajax gallery so it can be used for all sites with no changes
 * as it self configures based on SITE_NAME
 *
 * rather than pass isOldSkin into components pass actual colours. And use SITE_NAME to determine what to pass
 * IS_BOOMERANG =
 * IS_CARTOONITO = isOldSkin
 * IS_BOINGTV = isOldSkin
 * IS_TOONAMI = isOldSkin
 *
 *
 * cms : use cms passed colour eg:
 * const blockColour = siteStyles.colours.carousel.bg === 'cms' ? 'b_' + colour : siteStyles.colours.carousel.bg;
 */

/*
 * TODO: Add other sites colours when moving across
 */
const styleFallback = {
  groupColourBlocks: true,
  coloursByPage: {
    default: { content: 'b_white', carousel: 'b_white', feature: 'b_white', related: 'b_white' },
  },
};
const siteStyles = {
  boomerang: {
    groupColourBlocks: false,
    // { content: 'b_yellow', carousel: 'cms', feature: 'b_pink' }, // boomerang old skin
    coloursByPage: {
      default: { content: 'b_white', carousel: 'b_white', feature: 'b_white', related: 'b_white' },
      games: { content: 'transparent', carousel: 'transparent', feature: 'b_white', related: 'b_white' },
      web_game_category: { content: 'transparent', carousel: 'transparent', feature: 'b_white', related: 'b_white' },
    },
  },
  cartoonito: {
    groupColourBlocks: true,
    coloursByPage: {
      default: { content: 'b_purple', carousel: 'cms', feature: 'b_pink', related: 'transparent' },
      games: { content: 'transparent', carousel: 'transparent', feature: 'b_pink', related: 'b_yellow' },
      web_game_category: { content: 'transparent', carousel: 'transparent', feature: 'b_pink', related: 'b_yellow' },
      gameplay: { content: 'b_purple', carousel: 'cms', feature: 'b_pink', related: 'b_yellow' },
      videoplayer: { content: 'b_purple', carousel: 'cms', feature: 'b_pink', related: 'b_blue' },
      quizplay: { content: 'b_purple', carousel: 'cms', feature: 'b_pink', related: 'b_purple' },
    },
  },
  boingtv: {
    groupColourBlocks: true,
    coloursByPage: {
      default: { content: 'b_yellow', carousel: 'cms', feature: 'b_pink', related: 'b_white' },
    },
  },
  toonami: {
    groupColourBlocks: true,
    coloursByPage: {
      default: { content: 'b_yellow', carousel: 'cms', feature: 'b_pink', related: 'b_white' },
    },
  },
};

export const getSiteStyles = (pageId = PAGE_ID) => {
  // uses default colours, unless page specific colours are defined
  const lookup = siteStyles[SITE_NAME] ? siteStyles[SITE_NAME] : styleFallback;
  const colours = lookup.coloursByPage[pageId] ? lookup.coloursByPage[pageId] : lookup.coloursByPage.default;
  return {
    groupColourBlocks: lookup.groupColourBlocks,
    colours,
  };
};

/**
 * Carousel
 */
export const getCarouselPageID = (pageId) => {
  if (pageId === PAGE.SHOWS) {
    return SUB_PAGE.SHOW.replace('${id}', SHOW_ID);
  } else if (pageId === PAGE.CONTENT_HUBS) {
    return SUB_PAGE.CONTENT_HUB.replace('${id}', HUB_ID);
  } else {
    return pageId;
  }
};

const ASSETS_URL = CONFIG_DATA.assets_url;
const language = LOCALE.toLowerCase().split('_')[0];
export const PLACEHOLDER = `${ASSETS_URL}images/placeholder/placeholder_${language}.svg`;
export const SPINNER = `${ASSETS_URL}images/spinner.svg`;

const dump = {
  SITE_NAME,
  PAGE_ID,
  RELATED_ITEMS,
  CATEGORY_DATA,
  FILTER_OPTIONS,
  RENDER_FEATURE_BLOCK,
  HAS_CONTENT_BLOCK,
  HAS_CAROUSELS,
  NEW_STYLE,
  USE_16_9_THUMBNAILS,
};
LOG.enabled && console.log(`%cpage data`, LOG.clrBlack, dump);
