import React from 'react';

const PlatformIcons = ({ platforms, icon, ensightenTracking }) => {
  const renderPlatformIcon = (data, index) => {
    const { platform, titleAttribute, iconAlt, icon, link, target, amc_store, amc_show, amc_title, amc_redirect, amc_exit_track } = data;
    return (
      <a
        key={platform + '_' + amc_title}
        href={link}
        title={titleAttribute}
        target={target}
        data-amcstore={amc_store}
        data-amcshow={amc_show}
        data-amctitle={amc_title}
        data-amcredirect={amc_redirect}
        data-amcexittrack={amc_exit_track}
        onClick={ensightenTracking ? ensightenTracking : null}
        rel={target === '_blank' ? 'noopener' : ''}
      >
        <img className="content_item__platform-icon" src={icon} alt={`${titleAttribute} ${platform}`} />
      </a>
    );
  };

  return platforms ? (
    <div className="content_item__platform-icons">{platforms.map(renderPlatformIcon)}</div>
  ) : (
    <img className="content_item__icon content_item__icon--platform" src={icon} alt={iconAlt} />
  );
};
export default PlatformIcons;
