import React from 'react';
import { LazyImage } from 'react-lazy-images';
import Ribbon from 'components/Ribbon';
import PlatformIcons from 'components/PlatformIcons';
import { triggerEnsightenTrackingWebGame } from 'constants/EnsightenTracking';
import { processImages } from 'utils/general';
import { LOG } from 'constants/App';

const EntryItemApp = (props) => {
  const { isNewLabel, ageRating, title, description, alt_tag, icon, link, target, iconAlt } = props;
  const { img_small, img_medium, img_large, img_placeholder, isFallbackImage } = props;
  const { isNewContent, content_type, platforms, quiz_type } = props;
  let li_clazz = content_type == 'quiz' ? `${quiz_type} quiz article` : `${content_type} article`;
  if (isNewContent) {
    li_clazz += ' new';
  }

  LOG.enabled && console.log(`%cEntryItemApp | ${isFallbackImage ? 'fallback' : '16:9'} : ${title}`, isFallbackImage ? 'background: pink; color: black' : 'background: #666; color: white');

  const triggerEnsightenTracking = () => {
    triggerEnsightenTrackingWebGame(props);
  };

  const bindedTriggerEnsightenTracking = triggerEnsightenTracking.bind(this);

  let a_clazz = `content_item content_item--${content_type} ready_to_animate`;

  const { placeholder_lazy, srcSet, src } = processImages(img_placeholder, [img_small, img_medium, img_large]);

  return (
    <li className={li_clazz}>
      <LazyImage
        src={src}
        srcSet={srcSet}
        alt={alt_tag}
        debounceDurationMs={100 + Math.random() * 300}
        placeholder={({ imageProps, ref }) => (
          <div ref={ref} className={a_clazz} href={link} target={target}>
            <span className="content_item_inner">
              <img className="sl-img radius-top" src={placeholder_lazy} alt={imageProps.alt} />
              <span className="content_item_label label">
                <img className="content_item__icon" src={icon && icon} alt={iconAlt} />

                {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                <h3>{title}</h3>
                <p>{description}</p>
              </span>
              {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
            </span>
          </div>
        )}
        actual={({ imageProps }) => (
          <div className={a_clazz + ' animate'}>
            <span className="content_item_inner">
              {isFallbackImage ? (
                <img className="sl-img radius-top" {...imageProps} />
              ) : (
                <div className="content_item_inner__image">
                  <img className="sl-img radius-top" {...imageProps} />
                </div>
              )}
              <span className="content_item_label label">
                <PlatformIcons platforms={platforms} ensightenTracking={bindedTriggerEnsightenTracking} />

                {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                <h3>{title}</h3>
                <p>{description}</p>
              </span>
              {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
            </span>
          </div>
        )}
        error={() => (
          <div className={a_clazz + ' animate'}>
            <span className="content_item_inner">
              <img className="sl-img radius-top" src={placeholder_lazy} alt={alt_tag} />
              <span className="content_item_label label">
                <PlatformIcons platforms={platforms} ensightenTracking={bindedTriggerEnsightenTracking} />

                {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                <h3>{title}</h3>
                <p>{description}</p>
              </span>
              {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
            </span>
          </div>
        )}
      />
    </li>
  );
};
export default EntryItemApp;
