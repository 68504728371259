import React from 'react';

export const Error = ({ wrapClass, filterDrop, featureBlockPlaceholder }) => {
  return (
    <>
      <section className="block-list block-list-filter">
        <div className={wrapClass}>
          {filterDrop}
          <h2 className="cbtitle">Ooops!! Something has gone wrong</h2>
        </div>
      </section>
      {featureBlockPlaceholder}
    </>
  );
};
