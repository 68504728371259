import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBlocks, getDidError, getIsInitialised, getIsReady } from 'store/carousels/carouselsSelectors';
import { getAllCopy } from 'store/copy/copySelectors';
import { fetchCarousels, setInitialised } from 'store/carousels/carouselsActions';
import { getBlockItems } from 'store/feature/featureSelectors';
import CarouselBlock from 'components/carousel/CarouselBlock';
import FeatureBlock from 'components/feature/FeatureBlock';
import CarouselError from 'components/carousel/CarouselError';
import { getCarouselBreakpoints } from 'store/app/appSelectors';
import { LOG } from 'constants/App';

class Carousels extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isInitialised, hasBeenVisible } = this.props;
    if (!isInitialised && hasBeenVisible) {
      LOG.enabled && console.log(`%cCarousels.componentDidUpdate`, LOG.clrBlack);
      this.props.setInitialised();
      this.props.fetchCarousels();
    }
  }

  render() {
    const { siteStyles, ageRating, isSmartphone, rightPadStyle, spinner, carouselPlaceholder, hasFeatureUnderCarousels, isRTL } = this.props; // passed through
    const { isReady, isError, blocks, copy, featureBlocks, carouselBreakpoints } = this.props;
    if (isError) {
      return <CarouselError />;
    } else {
      return (
        <>
          <CarouselBlock
            isReady={isReady}
            blocks={blocks}
            copy={copy}
            siteStyles={siteStyles}
            isSmartphone={isSmartphone}
            ageRating={ageRating}
            rightPadStyle={rightPadStyle}
            spinner={spinner}
            isRTL={isRTL}
            carouselPlaceholder={carouselPlaceholder}
            carouselBreakpoints={carouselBreakpoints}
          />
          {hasFeatureUnderCarousels && <FeatureBlock copy={copy} blocks={featureBlocks} siteStyles={siteStyles} ageRating={ageRating} />}
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    copy: getAllCopy(state),
    isInitialised: getIsInitialised(state),
    isReady: getIsReady(state),
    isError: getDidError(state),
    blocks: getBlocks(state),
    featureBlocks: getBlockItems(state),
    carouselBreakpoints: getCarouselBreakpoints(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInitialised: () => {
      dispatch(setInitialised());
    },
    fetchCarousels: () => {
      dispatch(fetchCarousels());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Carousels);
