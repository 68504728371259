import React from 'react';
import { USE_WEBP } from 'constants/App';

const Ribbon = ({ isNewContent, isNewLabel }) => {
  return isNewContent ? (
    <span className={`ribbon ribbon--small ${USE_WEBP ? 'webp' : 'non-webp'}`}>
      <span>{isNewLabel}</span>
    </span>
  ) : null;
};
export default Ribbon;
