import React from 'react';
import EntryItemApp from 'components/entry/EntryItemApp';
import EntryItemOther from 'components/entry/EntryItemOther';

const Entries = (props) => {
  const { entries, copy, ageRating, contentBlockStyle } = props;
  const { home_new_content_title: isNewLabel } = copy;

  const renderEntry = entries.map((data) => {
    if (data && data.platforms) {
      return <EntryItemApp key={data.id} isNewLabel={isNewLabel} ageRating={ageRating} {...data} />;
    } else if (data) {
      return <EntryItemOther key={data.id} isNewLabel={isNewLabel} ageRating={ageRating} {...data} />;
    }
  });

  return <ul className={contentBlockStyle}>{renderEntry}</ul>;
};

export default Entries;
