import { FEATURE_BOOTSTRAP } from 'constants/Actions';

import { parseFeatureEntry } from 'store/models/EntryParser';

const bootstrap = (state, featureBlock) => {
  /**
   * strip out any empty block items
   */
  const blockItems = featureBlock.reduce((acc, obj) => {
    const isEmpty = Object.keys(obj).length === 0;
    if (!isEmpty) {
      acc.push(obj);
    }
    return acc;
  }, []);
  /**
   * parse remaining
   */
  const entries = blockItems.map((item, i) => {
    return parseFeatureEntry(item.content_type, item);
  });
  return {
    type: FEATURE_BOOTSTRAP,
    entries,
  };
};

export const bootstrapFeature = (featureBlock) => {
  return (dispatch, getState) => {
    return dispatch(bootstrap(getState(), featureBlock));
  };
};
