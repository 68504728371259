import React, { Component } from 'react';
import { connect } from 'react-redux';

import Categories from 'containers/Categories';
import Carousels from 'containers/Carousels';
import Content from 'containers/Content';
import CarouselsWithFeature from 'containers/CarouselsWithFeature';
import ObserverOnce from 'components/io/ObserverOnce';
import { updatePageId } from 'store/app/appActions';
import {
  getAgeRating,
  getCarouselPlaceholder,
  getContentBlocks,
  getDeviceIsSmartphone,
  getHasActiveCategorys,
  getHasCarousels,
  getHasContentBlock,
  getHasFeatureInCarousel,
  getHasFeatures,
  getIsRTL,
  getRightPadStyle,
  getSiteStyles,
  getSpinner,
} from 'store/app/appSelectors';
import { getRelatedItems, getRelatedItemsTitle, getRelatedItemsWrapperClass } from 'store/relatedItems/relatedSelectors';
import RelatedItemsBlock from 'components/relatedItems/RelatedItemsBlock';

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevPathname = prevProps.location.pathname;
    const pathname = this.props.location.pathname;

    if (prevPathname !== pathname) {
      const { isGamePage, isGameCategoryPage } = this.props;
      this.props.updatePageId({ isGamePage, isGameCategoryPage, pathname });
    }
  }

  render() {
    const { location } = this.props.history;
    const { hasContentBlock, hasCarousels, hasActiveCategorys, hasFeatures, featureInCarousel } = this.props;
    const { siteStyles, ageRating, isSmartphone, isRTL, rightPadStyle, spinner, carouselPlaceholder, contentBlocks } = this.props;
    const { relatedItems, relatedItemsWrapperClass, relatedItemsTitle } = this.props;

    const hasFeatureInCarousels = hasFeatures && featureInCarousel;
    const hasFeatureUnderCarousels = hasFeatures && !hasContentBlock;
    const hasFeatureUnderContent = hasFeatures && !featureInCarousel;

    const relatedItemsBlock = (
      <RelatedItemsBlock isSmartphone={isSmartphone} title={relatedItemsTitle} blocks={relatedItems} wrapperClass={relatedItemsWrapperClass} ageRating={ageRating} isRTL={isRTL} />
    );

    let carousels = null;
    if (hasCarousels) {
      carousels = hasFeatureInCarousels ? (
        <ObserverOnce>
          {(hasBeenVisible) => (
            <CarouselsWithFeature
              hasBeenVisible={hasBeenVisible}
              contentBlocks={contentBlocks}
              siteStyles={siteStyles}
              ageRating={ageRating}
              isSmartphone={isSmartphone}
              rightPadStyle={rightPadStyle}
              spinner={spinner}
              carouselPlaceholder={carouselPlaceholder}
              isRTL={isRTL}
            />
          )}
        </ObserverOnce>
      ) : (
        <ObserverOnce>
          {(hasBeenVisible) => (
            <Carousels
              hasBeenVisible={hasBeenVisible}
              contentBlocks={contentBlocks}
              siteStyles={siteStyles}
              ageRating={ageRating}
              isSmartphone={isSmartphone}
              rightPadStyle={rightPadStyle}
              spinner={spinner}
              carouselPlaceholder={carouselPlaceholder}
              isRTL={isRTL}
              hasFeatureUnderCarousels={hasFeatureUnderCarousels}
            />
          )}
        </ObserverOnce>
      );
    }
    return (
      <>
        {relatedItemsBlock}
        {hasActiveCategorys && <Categories isRTL={isRTL} />}
        <div className="gallery__content-blocks">
          {carousels}
          {hasContentBlock && (
            <ObserverOnce>
              {(hasBeenVisible) => (
                <Content hasBeenVisible={hasBeenVisible} location={location} siteStyles={siteStyles} ageRating={ageRating} spinner={spinner} hasFeatureUnderContent={hasFeatureUnderContent} />
              )}
            </ObserverOnce>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasContentBlock: getHasContentBlock(state),
    hasCarousels: getHasCarousels(state),
    hasActiveCategorys: getHasActiveCategorys(state),
    hasFeatures: getHasFeatures(state),
    featureInCarousel: getHasFeatureInCarousel(state),
    siteStyles: getSiteStyles(state),
    ageRating: getAgeRating(state),
    isSmartphone: getDeviceIsSmartphone(state),
    isRTL: getIsRTL(state),
    rightPadStyle: getRightPadStyle(state),
    spinner: getSpinner(state),
    carouselPlaceholder: getCarouselPlaceholder(state),
    contentBlocks: getContentBlocks(state),
    relatedItems: getRelatedItems(state),
    relatedItemsWrapperClass: getRelatedItemsWrapperClass(state),
    relatedItemsTitle: getRelatedItemsTitle(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePageId: (opts) => {
      dispatch(updatePageId(opts));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
