/**
 * https://jmperezperez.com/high-performance-lazy-loading/
 */
import React, { Component } from 'react';

class ObserverOnce extends Component {
  constructor() {
    super();
    this.state = { hasBeenVisible: false };
    this.io = null;
    this.container = null;
  }
  componentDidMount() {
    this.io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.setState({ hasBeenVisible: true });
          this.io.disconnect();
        }
      });
    }, {});
    this.io.observe(this.container);
  }
  componentWillUnmount() {
    if (this.io) {
      this.io.disconnect();
    }
  }
  render() {
    return (
      <div
        ref={(div) => {
          this.container = div;
        }}
      >
        {Array.isArray(this.props.children) ? this.props.children.map((child) => child(this.state.hasBeenVisible)) : this.props.children(this.state.hasBeenVisible)}
      </div>
    );
  }
}
export default ObserverOnce;
