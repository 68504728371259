import { PLATFORM_ANDROID, PLATFORM_IOS, USER_PLATFORM } from 'constants/App';
import { FILTER_RESET, FILTER_SELECT, FILTERS_CREATE } from 'constants/Actions';

import { getHasEntries } from 'store/entries/entriesSelectors';
import { batchFetchEntries } from 'store/entries/entriesActions';
import { createFilter } from 'store/filters/filtersFactory';
import { getIsGameOrGameCategoryPage, getPageId } from 'store/app/appSelectors';
import { FILTERS_BY_PAGE } from 'constants/Const';

const REMOVE_IOS = USER_PLATFORM === PLATFORM_ANDROID;
const REMOVE_ANDROID = USER_PLATFORM === PLATFORM_IOS;

const removePlatformFilter = (filterId) => {
  return (REMOVE_ANDROID && filterId === PLATFORM_ANDROID) || (REMOVE_IOS && filterId === PLATFORM_IOS);
};

const createFilters = (state, pageId, filterOptions) => {
  const filterOptionsAllIDs = filterOptions.reduce((acc, id) => {
    if (!removePlatformFilter(id)) {
      acc.push(id);
    }
    return acc;
  }, []);
  const filterOptionsByID = filterOptions.reduce((acc, id) => {
    if (!removePlatformFilter(id)) {
      acc[id] = createFilter(state, id, pageId);
    }
    return acc;
  }, {});
  return {
    type: FILTERS_CREATE,
    filterOptionsAllIDs,
    filterOptionsByID,
    selectedFilterId: filterOptionsAllIDs[0],
  };
};

const _selectFilter = (filterId) => {
  return {
    type: FILTER_SELECT,
    filterId,
  };
};

const _resetFilter = () => {
  return {
    type: FILTER_RESET,
  };
};

export const selectFilter = (filterId) => {
  return (dispatch, getState) => {
    dispatch(_selectFilter(filterId));
    if (!getHasEntries(getState())) {
      dispatch(batchFetchEntries(getState()));
    }
  };
};

export const resetFilter = () => {
  return (dispatch, getState) => {
    const state = getState();
    const pageId = getPageId(state);
    const IsGameOrGameCategoryPage = getIsGameOrGameCategoryPage(state);
    if (IsGameOrGameCategoryPage) {
      // Re create filters for game or web_game_category pages
      // console.log(`%c@@@@@@@@@@@@@@@ resetFilter | pageId ${pageId}`, 'color: green;');
      const filterOptions = FILTERS_BY_PAGE[pageId];
      dispatch(createFilters(getState(), pageId, filterOptions));
    }
    dispatch(_resetFilter());
  };
};

export const fetchFilters = (filterOptions) => {
  return (dispatch, getState) => {
    const pageId = getPageId(getState());
    return dispatch(createFilters(getState(), pageId, filterOptions));
  };
};
