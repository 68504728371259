import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAdTechTag, getAdType, getDoubleclickESID, getDoubleclickID, getEnsightenID, getEnsightenTrigger, getRawScriptTag, getScriptURL, getRawScriptTagFeature } from 'store/ads/adsSelectors';
import * as ADS from 'constants/Ads';
import DoubleClickAd from 'components/ads/DoubleClickAd';
import AdtechAd from 'components/ads/AdtechAd';
import ScriptAd from 'components/ads/ScriptAd';
import ScriptRawAd from 'components/ads/ScriptRawAd';
import EnsightenAd from 'components/ads/EnsightenAd';
import ScriptRawURLAd from 'components/ads/ScriptRawURLAd';

import { LOG } from 'constants/App';

class Ad extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { advertising_slug, adType, adTag, doubleclickID, doubleclickESID, scriptURL, rawScriptTag, ensightenID, ensightenTrigger, rawScriptTagFeature, mpu_type = '' } = this.props;
    let ad = null;
    let enabled = true;
    const randomID = 'scripturl_' + Math.round(Math.random() * 1000);
    switch (adType) {
      case ADS.ADTECH:
        ad = <AdtechAd tag={adTag} />;
        break;
      case ADS.SCRIPT:
        ad = <ScriptAd id={randomID} tag={adTag} scriptURL={scriptURL} />;
        break;
      // case ADS.SCRIPT_RAW:
      //   ad = <ScriptRawAd id={randomID} tag={adTag} scriptURL={scriptURL} />;
      //   break;
      case ADS.SCRIPT_RAW: {
        let scriptURL = null;
        const targetID = mpu_type === 'feature' ? rawScriptTagFeature : rawScriptTag;
        console.log('targetID', targetID, 'rawScriptTagFeature', rawScriptTagFeature);
        let tag = targetID;
        const extractedScriptURLArray = /<script.*?src="(.+)"><\/script>/gi.exec(targetID);
        // console.log('extractedScriptURLArray', extractedScriptURLArray);
        /*
         * NOTE : This is very hacky
         */
        if (extractedScriptURLArray) {
          const extractedScriptURL = extractedScriptURLArray[1];
          tag = targetID.replace(extractedScriptURLArray[0], '');
          // convert entities
          let txt = document.createElement('textarea');
          txt.innerHTML = extractedScriptURL;
          scriptURL = txt.value;
          ad = <ScriptRawURLAd tag={tag} scriptURL={scriptURL} />;
        } else {
          ad = <ScriptRawAd tag={tag} />;
        }
        break;
      }
      case ADS.DOUBLECLICK:
        ad = <DoubleClickAd id={doubleclickID} />;
        break;
      case ADS.ENSIGHTEN:
        ad = <EnsightenAd id={ensightenID} trigger={ensightenTrigger} />;
        break;
      case ADS.DOUBLECLICK_ES:
        ad = <DoubleClickAd id={doubleclickESID} />;
        break;
      default:
        ad = null;
        enabled = true; // force render an empty spot for undefined ad type
    }
    LOG.enabled && console.log(`%c++++++ Ad.render | adType ${adType}`, LOG.clrBlack);
    /**
     * This needs to be deferred until after ad libs have instantiated
     */
    if (!enabled) {
      return null;
    } else {
      return (
        <div className="featured_item mpu platform_all">
          <div className="mpu_inner">
            {ad}
            <div className="mpu_label">{advertising_slug}</div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    adType: getAdType(state),
    doubleclickID: getDoubleclickID(state),
    doubleclickESID: getDoubleclickESID(state),
    adTag: getAdTechTag(state),
    scriptURL: getScriptURL(state),
    rawScriptTag: getRawScriptTag(state),
    rawScriptTagFeature: getRawScriptTagFeature(state),
    ensightenID: getEnsightenID(state),
    ensightenTrigger: getEnsightenTrigger(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Ad);
