// noinspection JSUnreachableSwitchBranches

import { CBTITLES, PAGE_DATA, SHOW_NAME, SHOW_SUBPAGE_ID, SHOW_TITLE } from 'constants/App';
import { PAGE, SUB_PAGE } from 'constants/Const';
import { getSelectedFilterId } from 'store/filters/filtersSelectors';
import { FILTERS } from 'store/filters/filtersFactory';
import { getPageId } from 'store/app/appSelectors';

export const getAllCopy = (state) => {
  return state.copy;
};

export const getCopy = (state, id, defaultId) => {
  return state.copy[id] || state.copy[defaultId] || `${id} not found`;
};

export const getVideosPageTitle = (state) => {
  return state.copy.videos_all_heading_txt || `videos_all_heading_txt not found`;
};

export const getComicsPageTitle = (state) => {
  return state.copy.comics_all_heading_txt || `comics_all_heading_txt not found`;
};

export const getAppsPageTitle = (state) => {
  return state.copy.apps_all_heading_txt || `apps_all_heading_txt not found`;
};

export const getGamesPageTitle = (state) => {
  return state.copy.games_all_heading_txt || `games_all_heading_txt not found`;
};

export const getGamesCategoryPageTitle = (state) => {
  return state.copy.games_category_all_heading_txt || `games_category_all_heading_txt not found`;
};

export const getQuizzesPageTitle = (state) => {
  return state.copy.quizzes_all_heading_txt || `quizzes_all_heading_txt not found`;
};

export const getRelatedItemsBlockTitle = (state, contentType, showName) => {
  let title = '';
  let template = '';
  switch (contentType) {
    case 'web_game':
      template = state.copy.gameplay_show_games_txt;
      break;
    case 'video':
      template = state.copy.videoplayer_show_videos_txt;
      break;
    case 'quiz':
      template = state.copy.quizplay_show_quizzes_txt;
      break;
    case 'animals_activity':
      template = state.copy.animals_activitiesplay_show_animals_activities_txt;
      break;
    case 'letscreate_activity':
      template = state.copy.letscreate_activitiesplay_show_letscreate_activities_txt;
      break;
    default:
      template = 'getRelatedItemsBlockTitle {show-name}';
      break;
  }
  title = showName ? template.replace('{show-name}', showName) : template;
  return title;
};

export const getShowsPageTitle = (state) => {
  if (SHOW_SUBPAGE_ID) {
    let copy;
    switch (SHOW_SUBPAGE_ID) {
      case PAGE.VIDEOS:
        copy = state.copy.videos_all_heading_show_txt;
        break;
      case PAGE.GAMES:
        copy = state.copy.games_all_heading_show_platform_txt;
        break;
      case PAGE.QUIZZES:
        copy = state.copy.quizzes_all_heading_show_platform_txt;
        break;
      case PAGE.COMICS:
        copy = state.copy.comics_all_heading_show_txt;
        break;
      case PAGE.EDITORIALS:
        copy = state.copy.editorials_all_heading_show_txt || 'editorials_all_heading_show_txt not found';
        break;
    }
    copy = copy.replace('{show-name}', SHOW_NAME);
    return copy;
  } else {
    const filterId = getSelectedFilterId(state);
    // console.log('????? getShowsPageTitle', SHOW_SUBPAGE_ID, filterId, FILTERS.ALL);
    if (!filterId) {
      return '';
    } else if (filterId === FILTERS.ALL) {
      return SHOW_TITLE;
    }
    const cblookup = `_cb${filterId}`;

    if (typeof CBTITLES[cblookup] !== 'undefined' && CBTITLES[cblookup] !== '') {
      return CBTITLES[cblookup];
    }

    const lookup = `shows_all_${filterId}`;
    if (state.copy[lookup]) {
      return state.copy[lookup].replace('{show-name}', SHOW_NAME);
    } else {
      return lookup + ' not found';
    }
  }
};

export const getSubPageIntroText = (state) => {
  if (SHOW_SUBPAGE_ID) {
    let copy;
    switch (SHOW_SUBPAGE_ID) {
      case PAGE.VIDEOS:
        copy = PAGE_DATA.introduction_text_videos_all || '';
        break;
      case PAGE.GAMES:
        copy = PAGE_DATA.introduction_text_games_all || '';
        break;
      case PAGE.QUIZZES:
        copy = PAGE_DATA.introduction_text_quizzes_all || '';
        break;
      case PAGE.COMICS:
        copy = PAGE_DATA.introduction_text_comics_all || '';
        break;
      default:
        copy = false;
        break;
    }
    return copy;
  }
  return false;
};

export const getPageTitle = (state) => {
  const pageId = getPageId(state);
  // console.log(`getPageTitle for ${pageId}`);
  switch (pageId) {
    case SUB_PAGE.WEB_GAME_CATEGORY:
      return getGamesCategoryPageTitle(state);
    case PAGE.APPS:
      return getAppsPageTitle(state);
    case PAGE.GAMES:
      return getGamesPageTitle(state);
    case PAGE.VIDEOS:
      return getVideosPageTitle(state);
    case PAGE.COMICS:
      return getComicsPageTitle(state);
    case PAGE.QUIZZES:
      return getQuizzesPageTitle(state);
    case PAGE.SHOWS:
      return getShowsPageTitle(state);
  }
};
