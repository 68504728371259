export const getFilterOptions = (state) => {
  return state.filters.filterOptionsAllIDs.map((id) => {
    return state.filters.filterOptionsByID[id];
  });
};

export const getSelectedFilterId = (state) => {
  return hasSelectedFilterId(state) ? state.filters.selectedFilterId : false;
};

export const hasSelectedFilterId = (state) => {
  return !!state.filters.selectedFilterId;
};

export const getSelectedFilterLabel = (state) => {
  return hasSelectedFilterId(state) ? state.filters.filterOptionsByID[state.filters.selectedFilterId].label : '';
};

export const getSelectedFilter = (state) => {
  return hasSelectedFilterId(state) ? state.filters.filterOptionsByID[state.filters.selectedFilterId].filter : {};
};
