import React, { Component } from 'react';

class DoubleClickAd extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { id } = this.props;
    if (window.googletag) {
      window.googletag.cmd.push(function () {
        window.googletag.display(id);
      });
    }
  }

  render() {
    const { id } = this.props;
    return <div id={id} />;
  }
}
export default DoubleClickAd;
