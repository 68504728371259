import { DEFAULT_ENTRY_QUERY_LIMIT } from 'constants/App';

const filterEntry = (nextState, id, filterId) => {
  if (nextState.categories.byID[filterId]) {
    nextState.categories.byID[filterId] = { ...nextState.categories.byID[filterId] };
    nextState.categories.byID[filterId].items = [...nextState.categories.byID[filterId].items];
    if (nextState.categories.byID[filterId].items.indexOf(id) === -1) {
      // only add if its a new external_id
      nextState.categories.byID[filterId].items.push(id);
      nextState.categories.byID[filterId].offset++;
    }
  } else {
    nextState.categories.byID[filterId] = { id: filterId, items: [], offset: 1, limit: DEFAULT_ENTRY_QUERY_LIMIT, total_count: DEFAULT_ENTRY_QUERY_LIMIT + 1, verified: false };
    nextState.categories.byID[filterId].items.push(id);
    nextState.categories.allIds.push(filterId);
  }
};

const addEntry = (nextState, entry, filterId) => {
  const { id } = entry;
  if (nextState.items.allIds.indexOf(id) === -1) {
    nextState.items.allIds.push(id);
    nextState.items.byID[id] = entry;
  } else {
    nextState.items.byID[id] = { ...entry };
  }
  filterEntry(nextState, id, filterId);
};

export const parseEntries = (state, nextState, action) => {
  nextState.isFetching = false;
  nextState.total_count = action.total_count;

  nextState.categories = { ...state.categories };
  nextState.categories.byID = { ...state.categories.byID };
  nextState.categories.allIds = [...state.categories.allIds];

  nextState.items = { ...state.items };
  nextState.items.byID = { ...state.items.byID };
  nextState.items.allIds = [...state.items.allIds];

  const filterId = action.filterId;

  for (const entry of action.entries) {
    addEntry(nextState, entry, filterId);
  }

  // Update active filter category totals
  if (nextState.categories.byID[filterId]) {
    nextState.categories.byID[filterId] = { ...nextState.categories.byID[filterId] };
  } else {
    nextState.categories.byID[filterId] = { id: filterId, items: [], offset: 0, limit: DEFAULT_ENTRY_QUERY_LIMIT, total_count: DEFAULT_ENTRY_QUERY_LIMIT + 1, verified: false };
  }
  nextState.categories.byID[filterId].total_count = action.total_count;
  nextState.categories.byID[filterId].verified = true;

  return nextState;
};
