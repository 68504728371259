const SECOND = 1000;
const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const NEW_CONTENT_TIME = 21 * DAY;

export const isNewContent = (published_date) => {
  const publishedDate = parseInt(published_date) * 1000;
  const nowDate = Date.now();
  return publishedDate >= nowDate - NEW_CONTENT_TIME;
};
