import React from 'react';

export const MoreButton = ({ pageId, buttonClick, label }) => {
  const moreButtonClass = `more_button more_button--${pageId} show-more-items`;

  return (
    <section className="wrapper wrapper--no-top-bottom-padding">
      <button className={moreButtonClass} onClick={buttonClick}>
        <span className="icon">+</span>
        <span className="more_button__text">{label}</span>
      </button>
    </section>
  );
};
