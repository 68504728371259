export const getIsInitialised = (state) => {
  return state.carousels.initialised;
};

export const getIsReady = (state) => {
  return state.carousels.isReady;
};

export const getDidError = (state) => {
  return state.carousels.didInvalidate;
};

export const getIsFetchingCarouselsData = (state) => {
  return state.carousels.isFetching;
};

export const getBlockItems = (state, items = []) => {
  return items.map((id) => {
    return state.carousels.items.byID[id];
  });
};

export const getBlocks = (state) => {
  // return state.carousels.blocks.allIds.map((id) => {
  //   return state.carousels.blocks.byID[id];
  // });
  return state.carousels.blocks.allIds.reduce((acc, id, index) => {
    if (state.carousels.blocks.byID[id].itemsById.length > 0) {
      acc.push(state.carousels.blocks.byID[id]);
    }
    return acc;
  }, []);
};

export const getTopBlocks = (state) => {
  return state.carousels.blocks.allIds.reduce((acc, id, index) => {
    if (index < 2) {
      acc.push(state.carousels.blocks.byID[id]);
    }
    return acc;
  }, []);
};

export const getBottomBlocks = (state) => {
  return state.carousels.blocks.allIds.reduce((acc, id, index) => {
    if (index >= 2) {
      acc.push(state.carousels.blocks.byID[id]);
    }
    return acc;
  }, []);
};
