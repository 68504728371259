import { CAROUSELS_REQUEST, CAROUSELS_RECEIVE, CAROUSELS_INVALIDATE, CAROUSELS_RESET, CAROUSELS_INIT, CAROUSELS_BOOTSTRAP } from 'constants/Actions';

const initState = {
  blocks: { byID: {}, allIds: [] },
  items: { byID: {}, allIds: [] },
  initialised: false,
  didInvalidate: false,
  isFetching: true,
  isReady: false,
};

const addItem = (nextState, item) => {
  const { id } = item;
  if (nextState.items.allIds.indexOf(id) === -1) {
    nextState.items.allIds.push(id);
    nextState.items.byID[id] = item;
  } else {
    nextState.items.byID[id] = { ...item };
  }
};

const parseItems = (nextState, items) => {
  for (const item of items) {
    addItem(nextState, item);
  }
  return nextState;
};

const addBlock = (nextState, block) => {
  const { id, title, ribbon, colour } = block;
  if (nextState.blocks.allIds.indexOf(id) === -1) {
    nextState.blocks.allIds.push(id);
  }
  parseItems(nextState, block.items);
  const itemsById = block.items.reduce((acc, val) => {
    if (!acc.includes(val.id)) {
      acc.push(val.id);
    }
    return acc;
  }, []);
  nextState.blocks.byID[id] = { id, title, ribbon, colour, itemsById };
};

const parseEntries = (state, nextState, action) => {
  nextState.isFetching = false;
  nextState.blocks = { ...state.blocks };
  nextState.blocks.byID = { ...state.blocks.byID };
  nextState.blocks.allIds = [...state.blocks.allIds];
  nextState.items = { ...state.items };
  nextState.items.byID = { ...state.items.byID };
  nextState.items.allIds = [...state.items.allIds];
  for (const block of action.blocks) {
    addBlock(nextState, block);
  }
  return nextState;
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case CAROUSELS_BOOTSTRAP:
      return { ...state, ...parseEntries(state, nextState, action) };
    case CAROUSELS_INIT:
      nextState.initialised = true;
      return { ...state, ...nextState };
    case CAROUSELS_RESET:
      nextState.blocks = initState.blocks;
      nextState.items = initState.items;
      nextState.didInvalidate = false;
      nextState.isFetching = true;
      nextState.isReady = false;
      return { ...state, ...nextState };
    case CAROUSELS_INVALIDATE:
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      nextState.isReady = true;
      return { ...state, ...nextState };
    case CAROUSELS_REQUEST:
      nextState.isFetching = true;
      nextState.didInvalidate = false;
      return { ...state, ...nextState };
    case CAROUSELS_RECEIVE:
      nextState.isFetching = false;
      nextState.didInvalidate = false;
      nextState.isReady = true;
      return { ...state, ...parseEntries(state, nextState, action) };
    default:
      return state;
  }
};
export default reducer;
