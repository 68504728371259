import {
  ABORT_ENTRIES_REQUEST,
  CONTENT_COUNT_INVALIDATE,
  CONTENT_COUNT_RECEIVE,
  CONTENT_COUNT_REQUEST,
  ENTRIES_INIT,
  ENTRIES_INVALIDATE,
  ENTRIES_RECEIVE,
  ENTRIES_REQUEST,
  ENTRIES_RESET,
  NO_VIEWABLE_ENTERIES,
  QUEUE_ENTRIES_REQUEST,
} from 'constants/Actions';
import { CONTENT_TYPE } from 'constants/Const';

import { getIsFetchingContentCount, getIsFetchQueued, getLimitsByFilterId, getPageHasNoEntriesToRequest, getShouldTrackPageView } from 'store/entries/entriesSelectors';
import { fetchContentCountService, fetchEntriesService } from 'services/app';
import { getSelectedFilter, getSelectedFilterId } from 'store/filters/filtersSelectors';
import { parseAppEntry, parseEntry } from 'store/models/EntryParser';
import { getCategoryPageLoadTrackingData, getSelectedSubCategoryApiId } from 'store/category/categorySelectors';
import { getPageId } from 'store/app/appSelectors';
import { triggerEnsightenTrackingCategoryPageView } from 'constants/EnsightenTracking';

const _setInitialised = () => {
  return {
    type: ENTRIES_INIT,
  };
};

const invalidateContentCount = (results) => {
  return {
    type: CONTENT_COUNT_INVALIDATE,
    results,
  };
};

const invalidate = (results) => {
  return {
    type: ENTRIES_INVALIDATE,
    results,
  };
};

const requestContentCount = () => {
  return {
    type: CONTENT_COUNT_REQUEST,
  };
};

const updatePageHasNoEntries = () => {
  return {
    type: NO_VIEWABLE_ENTERIES,
  };
};

const receiveContentCount = ({ contentCount, total_count }) => {
  return {
    type: CONTENT_COUNT_RECEIVE,
    contentCount,
    total_count,
  };
};

const resetEntries = () => {
  return {
    type: ENTRIES_RESET,
  };
};

const queueRequestEntries = (trackPageView) => {
  return {
    type: QUEUE_ENTRIES_REQUEST,
    trackPageView,
  };
};

const abortRequestEntries = () => {
  return {
    type: ABORT_ENTRIES_REQUEST,
  };
};

const requestEntries = (trackPageView) => {
  return {
    type: ENTRIES_REQUEST,
    trackPageView,
  };
};

const receiveEntries = ({ pageId, filterId, entries, apps, total_count }) => {
  return {
    type: ENTRIES_RECEIVE,
    pageId,
    filterId,
    entries,
    apps,
    total_count: parseInt(total_count),
  };
};

export const parseContentCount = (json) => {
  const res = {};
  for (const key of Object.keys(json)) {
    res[key] = parseInt(json[key]);
  }
  return res;
};

export const parseEntries = (json) => {
  return json.reduce((arr, o) => {
    const data = parseEntry(o.content_type, o);

    if (data) arr.push(data);

    return arr;
  }, []);
};

export const parseApps = (json) => {
  return json.reduce((acc, obj) => {
    if (obj.content_type === CONTENT_TYPE.APP) {
      const platforms = parseAppEntry(obj.content_type, obj);
      return [...acc, ...platforms];
    } else {
      return acc;
    }
  }, []);
};

const trackAPageView = (getState) => {
  const trackingData = getCategoryPageLoadTrackingData(getState());
  trackingData && triggerEnsightenTrackingCategoryPageView(trackingData);
};

const _fetchEntries = (getState, trackPageView = false) => {
  const state = getState();
  const pageId = getPageId(state);
  const filteredLimit = getLimitsByFilterId(state);
  const offset = filteredLimit.offset;
  const limit = filteredLimit.limit;
  const filterId = getSelectedFilterId(state);
  const filter = getSelectedFilter(state);
  const isFetchingContentCount = getIsFetchingContentCount(state);
  const hasNoEntriesToRequest = getPageHasNoEntriesToRequest(state);
  const activeCategoryId = getSelectedSubCategoryApiId(state);

  if (isFetchingContentCount) {
    return (dispatch) => {
      dispatch(queueRequestEntries(trackPageView));
    };
  } else if (hasNoEntriesToRequest) {
    return (dispatch) => {
      dispatch(abortRequestEntries());
    };
  } else {
    return (dispatch) => {
      dispatch(requestEntries(trackPageView));
      fetchEntriesService({ pageId, offset, limit, filterId, filter, activeCategoryId })
        .then((data) => {
          const entries = parseEntries(data.entries);
          const apps = parseApps(data.entries);

          if (apps.length === 0 && entries.length === 0) {
            dispatch(updatePageHasNoEntries());
            return;
          }

          const total_count = data.total_count;
          dispatch(receiveEntries({ pageId, filterId, entries, apps, total_count }));

          if (trackPageView) {
            trackAPageView(getState);
          }
        })
        .catch((response) => {
          dispatch(invalidate({ response }));
          console.error(response);
        });
    };
  }
};

const fetchContentCounts = (getState) => {
  return (dispatch) => {
    dispatch(requestContentCount());
    fetchContentCountService()
      .then((data) => {
        const contentCount = parseContentCount(data.totals);
        const total_count = data.total_count;
        dispatch(receiveContentCount({ contentCount, total_count }));
        const state = getState();
        const isFetchQueued = getIsFetchQueued(state);
        const trackPageView = getShouldTrackPageView(state);
        if (isFetchQueued) {
          dispatch(_fetchEntries(getState, trackPageView));
        }
        // if (getPageHasNoEntriesToRequest(getState())) {
        //    * TODO : No entries so shutdown app
        // }
      })
      .catch((response) => {
        dispatch(invalidateContentCount({ response }));
        console.error(response);
      });
  };
};

export const setInitialised = () => {
  return (dispatch, getState) => {
    return dispatch(_setInitialised());
  };
};

export const fetchEntries = (trackPageView = false) => {
  return (dispatch, getState) => {
    dispatch(resetEntries());
    return dispatch(_fetchEntries(getState, trackPageView));
  };
};

export const batchFetchEntries = () => {
  return (dispatch, getState) => {
    return dispatch(_fetchEntries(getState));
  };
};

export const getContentCount = () => {
  return (dispatch, getState) => {
    return dispatch(fetchContentCounts(getState));
  };
};

// /**
//  * Strip out unused properties from object
//  */
// const { ratings, status, entrant, type, winner_types, prize, import_id, import_source, import_user_id, import_user_name, ...finalObj } = o;
