import { ADS_BOOTSTRAP } from 'constants/Actions';

const initState = {};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case ADS_BOOTSTRAP:
      nextState.data = action.data;
      nextState.adTechDesktopTag = action.adTechDesktopTag;
      nextState.adTechMobileTag = action.adTechMobileTag;
      nextState.adType = action.adType;
      return { ...state, ...nextState };
    default:
      return state;
  }
};

export default reducer;
