import { CATEGORY_BOOTSTRAP, SUBCATEGORY_CLEAR, SUBCATEGORY_SELECT } from 'constants/Actions';

const initState = {
  allCategorysIds: [],
  allCategorysById: {},
  activeCategoryId: null,
  selectedSubCategoryId: null,
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case CATEGORY_BOOTSTRAP:
      nextState.allCategorysIds = action.allCategorysIds;
      nextState.allCategorysById = action.allCategorysById;
      nextState.activeCategoryId = action.activeCategoryId;
      nextState.selectedSubCategoryId = action.selectedSubCategoryId;
      return { ...state, ...nextState };
    case SUBCATEGORY_CLEAR:
      nextState.selectedSubCategoryId = null;
      return { ...state, ...nextState };
    case SUBCATEGORY_SELECT:
      nextState.selectedSubCategoryId = action.subCategoryId;
      return { ...state, ...nextState };
    default:
      return state;
  }
};

export default reducer;
