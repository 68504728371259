import { getBootPageId, getSiteStyles } from 'store/app/appSelectors';
import { getRelatedItemsBlockTitle } from 'store/copy/copySelectors';
import { CAROUSEL_LIMIT } from 'constants/App';

export const getRelatedItems = (state) => {
  const ids = CAROUSEL_LIMIT === 0 ? state.relatedItems.allIds : state.relatedItems.allIds.slice(0, CAROUSEL_LIMIT); // limit to 3 on mobile
  const items = ids.map((id) => {
    return state.relatedItems.byID[id];
  });
  return items;
};

export const getRelatedItemsTitle = (state) => {
  if (state.relatedItems.allIds.length > 0) {
    const firstBlock = state.relatedItems.byID[state.relatedItems.allIds[0]];
    const showName = firstBlock?.showdata?.name;
    const contentType = firstBlock.content_type;
    return getRelatedItemsBlockTitle(state, contentType, showName);
  }
  return '';
};

export const getRelatedItemsWrapperClass = (state) => {
  const siteStyles = getSiteStyles(state);
  const bootPageId = getBootPageId(state);
  const blockColour = siteStyles?.colours?.related ?? 'b_yellow';
  switch (bootPageId) {
    case 'gameplay':
      return `wrapper ${blockColour}`;
    case 'quizplay':
      return `wrapper ${blockColour} wrapper--radius-bottom  `;
    case 'videoplayer':
      return `wrapper ${blockColour} wrapper--radius-top--margin-top wrapper--radius-bottom`;
    default:
      return `wrapper ${blockColour}`;
  }
};
