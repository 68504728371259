import { RELATED_ITEMS_BOOTSTRAP } from 'constants/Actions';

const initState = {
  byID: {},
  allIds: [],
};

const addItem = (nextState, item) => {
  const { id } = item;
  if (nextState.allIds.indexOf(id) === -1) {
    nextState.allIds.push(id);
    nextState.byID[id] = item;
  } else {
    nextState.byID[id] = { ...item };
  }
};

const parseItems = (state, nextState, items) => {
  for (const item of items) {
    addItem(nextState, item);
  }
  return nextState;
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case RELATED_ITEMS_BOOTSTRAP:
      nextState.byID = { ...state.byID };
      nextState.allIds = [...state.allIds];
      return { ...state, ...parseItems(state, nextState, action.entries) };
    default:
      return state;
  }
};

export default reducer;
