import { APP_BOOTSTRAP, APP_UPDATE_PAGE_ID, CATEGORY_FEATURED } from 'constants/Actions';
import { fetchEntries } from 'store/entries/entriesActions';
import { selectSubCategory } from 'store/category/categoryActions';
import { resetFilter } from 'store/filters/filtersActions';
import { getSubCategoryIdFromFriendlyURL } from 'store/category/categorySelectors';
import { LOG } from 'constants/App';

const bootstrap = (state, opts) => {
  const { pageId, hasContentBlock, hasCarousels, hasFeatures, featureInCarousel, filtersActive, siteStyles } = opts;
  const { contentBlocks, categoryData, ageRating, deviceType, spinner, isRTL, carouselPlaceholder } = opts;
  const { hasActiveCategorys, isGamePage, isGameCategoryPage } = categoryData;
  return {
    type: APP_BOOTSTRAP,
    hasContentBlock,
    hasCarousels,
    hasFeatures,
    featureInCarousel,
    filtersActive,
    siteStyles,
    ageRating,
    deviceType,
    isRTL,
    rightPadStyle: deviceType === 'smartphone' || isRTL ? {} : { paddingRight: 0 },
    spinner,
    carouselPlaceholder,
    contentBlocks,
    hasActiveCategorys,
    isGamePage,
    isGameCategoryPage,
    bootPageId: pageId,
  };
};

const _updatePageId = ({ isGamePage, isGameCategoryPage, pathname }) => {
  return {
    type: APP_UPDATE_PAGE_ID,
    isGamePage,
    isGameCategoryPage,
    pathname,
  };
};

export const updatePageId = ({ isGamePage, isGameCategoryPage, pathname }) => {
  LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ updatePageId :: isGamePage ${isGamePage} | isGameCategoryPage ${isGameCategoryPage} | pathname ${pathname}`, LOG.clrGreen);

  // scroll to gamesContent top
  const rect = document.getElementById('gamesContent').getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const destY = rect.top + scrollTop - 100;
  window.scrollTo(0, destY);

  return (dispatch, getState) => {
    dispatch(_updatePageId({ isGamePage, isGameCategoryPage, pathname }));
    if (isGamePage) {
      // game page defaults to using 'featured' category
      dispatch(selectSubCategory(CATEGORY_FEATURED));
    } else if (isGameCategoryPage) {
      // respond to browser buttons, using the friendlyURL in browser bar
      const subCategoryId = getSubCategoryIdFromFriendlyURL(getState(), pathname);
      subCategoryId && dispatch(selectSubCategory(subCategoryId));
    }
    dispatch(resetFilter());
    // isGamePage && dispatch(fetchCarousels());
    dispatch(fetchEntries(true));
  };
};

export const bootstrapApp = (opts) => {
  return (dispatch, getState) => {
    return dispatch(bootstrap(getState(), opts));
  };
};
