import React, { PureComponent } from 'react';
import Ribbon from 'components/Ribbon';
import { LazyImage } from 'react-lazy-images';
import { triggerEnsightenTrackingFeature } from 'constants/EnsightenTracking';
import { processImages } from 'utils/general';
import { LOG } from 'constants/App';

class FeatureItemOther extends PureComponent {
  constructor(props) {
    super(props);
    this.bindedTriggerEnsightenTracking = this.triggerEnsightenTracking.bind(this);
  }

  triggerEnsightenTracking() {
    triggerEnsightenTrackingFeature(this.props);
  }

  render() {
    const { isNewLabel, ageRating, content_type, title, description, alt_tag, titleAttribute, iconAlt, isNewContent, icon, link, target } = this.props;
    const { img_small, img_medium, img_large, img_placeholder, isFallbackImage } = this.props;
    const { amc_store, amc_show, amc_title, amc_redirect, amc_exit_track } = this.props;

    LOG.enabled && console.log(`%cFeatureItemOther | ${isFallbackImage ? 'fallback' : '16:9'} : ${title}`, isFallbackImage ? 'background: pink; color: black' : 'background: #666; color: white');

    let a_clazz = `content_item content_item--${content_type} ready_to_animate`;

    const { placeholder_lazy, srcSet, src } = processImages(img_placeholder, [img_small, img_medium, img_large]);

    return (
      <div className="featured_item platform_all">
        <LazyImage
          src={src}
          srcSet={srcSet}
          alt={alt_tag}
          debounceDurationMs={100 + Math.random() * 300}
          placeholder={({ imageProps, ref }) => (
            <a ref={ref} className={a_clazz} href={link} target={target} title={titleAttribute} rel={target === '_blank' ? 'noopener' : ''}>
              <span className="content_item_inner">
                <img className="sl-img radius-top" src={placeholder_lazy} alt={imageProps.alt} />
                <span className="content_item_label label">
                  <img className="content_item__icon" src={icon} alt={iconAlt} />

                  {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                  <h3>{title}</h3>
                  <p>{description}</p>
                </span>
                {<Ribbon />}
              </span>
            </a>
          )}
          actual={({ imageProps }) => (
            <a
              className={a_clazz + ' animate'}
              href={link}
              target={target}
              title={titleAttribute}
              data-amcstore={amc_store}
              data-amcshow={amc_show}
              data-amctitle={amc_title}
              data-amcredirect={amc_redirect}
              data-amcexittrack={amc_exit_track}
              onClick={this.bindedTriggerEnsightenTracking}
            >
              <span className="content_item_inner">
                {isFallbackImage ? (
                  <img className="sl-img radius-top" {...imageProps} />
                ) : (
                  <div className="content_item_inner__image">
                    <img className="sl-img radius-top" {...imageProps} />
                  </div>
                )}
                <span className="content_item_label label">
                  <img className="content_item__icon" src={icon} alt={iconAlt} />

                  {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                  <h3>{title}</h3>
                  <p>{description}</p>
                </span>
                {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
              </span>
            </a>
          )}
          error={() => (
            <a className={a_clazz + ' animate'} href={link} target={target} title={titleAttribute}>
              <span className="content_item_inner">
                <img className="sl-img radius-top" src={placeholder_lazy} alt={alt_tag} />
                <span className="content_item_label label">
                  <img className="content_item__icon" src={icon} alt={iconAlt} />

                  {ageRating.render && <img className="age_rating--small" src={ageRating.icon} alt={ageRating.iconAlt} />}

                  <h3>{title}</h3>
                  <p>{description}</p>
                </span>
                {<Ribbon isNewContent={isNewContent} isNewLabel={isNewLabel} />}
              </span>
            </a>
          )}
        />
      </div>
    );
  }
}

export default FeatureItemOther;
