import React from 'react';

import { CONTENT_TYPE } from 'constants/Const';
import { USE_WEBP } from 'constants/App';

import FeatureItemApp from 'components/feature/FeatureItemApp';
import FeatureItemOther from 'components/feature/FeatureItemOther';
import Ad from 'components/Ad';

const FeatureBlock = ({ copy, blocks, siteStyles, ageRating }) => {
  const { footer_featured_txt, home_new_content_title: isNewLabel, advertising_slug } = copy;

  const renderItems = blocks.map((data, index) => {
    if (data && data.content_type === CONTENT_TYPE.MPU) {
      return <Ad key={data.id} advertising_slug={advertising_slug} mpu_type={'feature'} />;
    } else if (data && data.platforms) {
      return <FeatureItemApp key={data.id} isNewLabel={isNewLabel} ageRating={ageRating} {...data} />;
    } else if (data) {
      return <FeatureItemOther key={data.id} isNewLabel={isNewLabel} ageRating={ageRating} {...data} />;
    }
  });

  let mpuClass = '';

  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i] && blocks[i].content_type === CONTENT_TYPE.MPU) {
      mpuClass = 'featured--mpuLayout';
    }
  }

  const colour = siteStyles.colours.feature;
  const extraClass = USE_WEBP ? 'webp' : 'non-webp';

  const hasBlocks = blocks.length > 0;
  return hasBlocks ? (
    <section className={'featured featured-size-3 ' + mpuClass}>
      <div className={'wrapper ' + colour}>
        <div className={`ribbon ribbon--large ribbon-purple ${extraClass}`}>
          <h2>{footer_featured_txt}</h2>
        </div>
        <div className="featured_wrapper">{renderItems}</div>
      </div>
    </section>
  ) : null;
};

export default FeatureBlock;
