export const PAGE = {
  HOME: 'homepage',
  VIDEOS: 'videos',
  GAMES: 'games',
  APPS: 'apps',
  QUIZZES: 'quizzes',
  COMICS: 'comics',
  EDITORIALS: 'editorials',
  ANIMALS: 'animals',
  LETSCREATE: 'letscreate',
  SHOWS: 'shows',
  UNLOCK: 'unlock',
  CONTENT_HUBS: 'content_hub',
};

export const FILTERS_BY_PAGE = {
  games: ['allnew', 'a-z', 'z-a', 'web', 'ios', 'android'],
  web_game_category: ['allnew', 'a-z', 'z-a'],
};

export const SUB_PAGE = {
  WEB_GAME_CATEGORY: 'web_game_category',
  ANIMALS_ACTIVITIES: 'animals_activities',
  ANIMALS_ACTIVITIESPLAY: 'animals_activitiesplay',
  ANIMALS_FUN_FACTS: 'animals_fun_facts',
  ANIMALS_POTW: 'animals_potw',
  ANIMALS_VIDEOPLAYER: 'animals_videoplayer',
  ANIMALS_VIDEOS: 'animals_videos',
  COMICPLAYER: 'comicplayer',
  CONTENT_HUB: 'content_hub_${id}',
  EDITORIALS: 'editorials',
  GAMEPLAY: 'gameplay',
  APPS: 'apps',
  HOMEPAGE: 'homepage',
  LETSCREATE_ACTIVITIES: 'letscreate_activities',
  LETSCREATE_ACTIVITIESPLAY: 'letscreate_activitiesplay',
  LETSCREATE_COLOURING: 'letscreate_colouring',
  LETSCREATE_COOKING: 'letscreate_cooking',
  LETSCREATE_CRAFT: 'letscreate_craft',
  LETSCREATE_GALLERY: 'letscreate_gallery',
  LETSCREATE_VIDEOPLAYER: 'letscreate_videoplayer',
  LETSCREATE_VIDEOS: 'letscreate_videos',
  QUIZPLAY: 'quizplay',
  SHOW: 'show_${id}',
  VIDEOPLAYER: 'videoplayer',
};

export const CONTENT_TYPE = {
  CHARACTER: 'character',
  ANIMALS_ACTIVITY: 'animals_activity',
  ANIMALS_VIDEO: 'animals_video',
  MPU: 'mpu', // used for ad,
  APP: 'app',
  APP_AND_WEB_GAME: 'app_and_web_game',
  CAMPAIGN: 'campaign',
  COMIC: 'comic',
  EDITORIAL: 'editorial',
  HUB: 'content_hub',
  LETSCREATE_ACTIVITY: 'letscreate_activity',
  LETSCREATE_VIDEO: 'letscreate_video',
  MANUAL: 'manual',
  QUIZ: 'quiz',
  VIDEO: 'video',
  WEB_GAME: 'web_game',
  SHOWS_ALL: 'show_content',
};
export const CONTENT_TYPE_LOOKUP = {
  web_game_category: CONTENT_TYPE.WEB_GAME,
  games: CONTENT_TYPE.APP_AND_WEB_GAME,
  videos: CONTENT_TYPE.VIDEO,
  comics: CONTENT_TYPE.COMIC,
  apps: CONTENT_TYPE.APP,
  quizzes: CONTENT_TYPE.QUIZ,
  editorials: CONTENT_TYPE.EDITORIAL,
  shows_all: CONTENT_TYPE.SHOWS_ALL,
};

export const CONTENT_TYPE_PREPEND_IDS = {
  animals_activity: 'aa_',
  animals_video: 'av_',
  app: 'ap_',
  campaign: 'cam_',
  comic: 'com_',
  content_hub: 'ch_',
  editorial: 'ed_',
  letscreate_activity: 'lca_',
  letscreate_video: 'lcv_',
  manual: 'man_',
  quiz: 'qui_',
  video: 'vid_',
  web_game: 'wg_',
  mpu: 'mpu_',
  web_game_html5: 'wg_',
};

/**
 * GAME FILTERS TITLES
 */
export const TITLE_FILTER_REPLACEMENT_IDS = 'ios,android,allnew,personality,points,polls';
