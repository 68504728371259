import { PAGE } from 'constants/Const';
import {
  ABORT_ENTRIES_REQUEST,
  CONTENT_COUNT_INVALIDATE,
  CONTENT_COUNT_RECEIVE,
  CONTENT_COUNT_REQUEST,
  ENTRIES_INIT,
  ENTRIES_INVALIDATE,
  ENTRIES_RECEIVE,
  ENTRIES_REQUEST,
  ENTRIES_RESET,
  NO_VIEWABLE_ENTERIES,
  QUEUE_ENTRIES_REQUEST,
} from 'constants/Actions';
import { parseGameEntries } from 'store/entries/parseGameEntries';
import { parseEntries } from 'store/entries/parseEntries';

const initState = {
  categories: { byID: {}, allIds: [] },
  items: { byID: {}, allIds: [] },
  apps: { byID: {}, allIds: [] },
  base_url: '',
  initialised: false,
  didInvalidateContentCount: false,
  isFetchingContentCount: false,
  didInvalidate: false,
  isFetchQueued: false,
  isFetching: false,
  isReady: false,
  totalCount: -1, // TODO : use this - if > 9 then we can render full sized filter block
  totalCounts: {},
  hasNoEntriesToRequest: false,
  trackPageView: false,
};

const parse = (state, nextState, action) => {
  if (action.pageId === PAGE.GAMES || action.pageId === PAGE.SHOWS) {
    // has app store entries
    // apps specific to game/shows sections. We need to create platform specific
    // app entries (based off store links available).
    return parseGameEntries(state, nextState, action);
  } else {
    return parseEntries(state, nextState, action);
  }
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case CONTENT_COUNT_INVALIDATE:
      nextState.isFetchingContentCount = false;
      nextState.didInvalidate = true;
      nextState.isReady = true;
      return { ...state, ...nextState };
    case CONTENT_COUNT_REQUEST:
      nextState.isFetchingContentCount = true;
      nextState.didInvalidateContentCount = false;
      return { ...state, ...nextState };
    case CONTENT_COUNT_RECEIVE:
      nextState.isFetchingContentCount = false;
      nextState.didInvalidateContentCount = false;
      nextState.totalCount = action.total_count;
      nextState.totalCounts = { ...action.contentCount };
      nextState.hasNoEntriesToRequest = action.total_count === 0;
      return { ...state, ...nextState };
    case ENTRIES_INIT:
      nextState.initialised = true;
      return { ...state, ...nextState };
    case ENTRIES_RESET:
      nextState.categories = initState.categories;
      nextState.items = initState.items;
      nextState.apps = initState.apps;
      nextState.hasNoEntriesToRequest = false;
      return { ...state, ...nextState };
    case QUEUE_ENTRIES_REQUEST:
      nextState.isFetchQueued = true;
      nextState.trackPageView = action.trackPageView;
      return { ...state, ...nextState };
    case ABORT_ENTRIES_REQUEST:
    case ENTRIES_INVALIDATE:
      nextState.isFetchQueued = false;
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      nextState.isReady = true;
      nextState.trackPageView = false;
      return { ...state, ...nextState };
    case ENTRIES_REQUEST:
      nextState.isFetchQueued = false;
      nextState.isFetching = true;
      nextState.didInvalidate = false;
      nextState.trackPageView = action.trackPageView;
      return { ...state, ...nextState };
    case NO_VIEWABLE_ENTERIES:
      nextState.hasNoEntriesToRequest = true;
      return { ...state, ...nextState };
    case ENTRIES_RECEIVE:
      nextState.isFetching = false;
      nextState.didInvalidate = false;
      nextState.isReady = true;
      nextState.trackPageView = false;
      nextState = parse(state, nextState, action);
      return { ...state, ...nextState };
    default:
      return state;
  }
};
export default reducer;
