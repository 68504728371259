/**
 * CATEGORIES
 */
export const APP_BOOTSTRAP = 'app.BOOTSTRAP';
export const APP_UPDATE_PAGE_ID = 'app.UPDATE_PAGE_ID';

/**
 * ENTRIES
 */
export const ABORT_ENTRIES_REQUEST = 'entries.ABORT_REQUEST';
export const QUEUE_ENTRIES_REQUEST = 'entries.QUEUE_REQUEST';
export const ENTRIES_INIT = 'entries.INIT';
export const ENTRIES_RESET = 'entries.RESET';
export const ENTRIES_REQUEST = 'entries.REQUEST';
export const ENTRIES_RECEIVE = 'entries.RECEIVE';
export const NO_VIEWABLE_ENTERIES = 'entries.NO_VIEWABLE_ENTERIES';
export const CONTENT_COUNT_REQUEST = 'contentCount.REQUEST';
export const CONTENT_COUNT_RECEIVE = 'contentCount.RECEIVE';

/**
 * CAROUSELS
 */
export const CAROUSELS_BOOTSTRAP = 'carousels.BOOTSTRAP';
export const CAROUSELS_INIT = 'carousels.INIT';
export const CAROUSELS_RESET = 'carousels.RESET';
export const CAROUSELS_REQUEST = 'carousels.REQUEST';
export const CAROUSELS_RECEIVE = 'carousels.RECEIVE';

/**
 * ERRORS
 */
export const CONTENT_COUNT_INVALIDATE = 'error.CONTENT_COUNT_INVALIDATE';
export const ENTRIES_INVALIDATE = 'error.ENTRIES_INVALIDATE';
export const CAROUSELS_INVALIDATE = 'error.CAROUSELS_INVALIDATE';

/**
 * FILTERS
 */
export const FILTERS_CREATE = 'filters.CREATE';
export const FILTER_SELECT = 'filters.SELECT';
export const FILTER_RESET = 'filters.RESET';

/**
 * CATEGORIES
 */
export const CATEGORY_FEATURED = 'featured';
export const CATEGORY_BOOTSTRAP = 'category.BOOTSTRAP';
export const SUBCATEGORY_SELECT = 'category.SUB_SELECT';
export const SUBCATEGORY_CLEAR = 'category.SUB_CLEAR';

/**
 * FEATURE
 */
export const FEATURE_BOOTSTRAP = 'feature.BOOTSTRAP';

/**
 * RELATED_ITEMS
 */
export const RELATED_ITEMS_BOOTSTRAP = 'related.BOOTSTRAP';

/**
 * ADS
 */
export const ADS_BOOTSTRAP = 'ads.BOOTSTRAP';
