import React from 'react';

export const Title = ({ ribbon, title, extraClass }) => {
  return ribbon ? (
    <span className={`ribbon ribbon--large ${extraClass}`}>
      <h2>{title}</h2>
    </span>
  ) : (
    <h2>{title}</h2>
  );
};
