import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectSubCategory } from 'store/category/categoryActions';
import { getActiveCategoryIds, getActiveCategoryItems } from 'store/category/categorySelectors';
import CategoryItem from 'components/category/CategoryItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, height: window.innerHeight };
    // this.swiper = React.createRef();
    this.onHandleResize = this.handleResize.bind(this);
  }

  get direction() {
    return this.state.width > 1168 ? 'vertical' : 'horizontal';
  }

  componentDidMount() {
    window.addEventListener('resize', this.onHandleResize);
    // if (this.swiper) {
    //   this.swiper.current.swiper.allowTouchMove = false;
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onHandleResize);
  }

  handleResize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { isRTL, activeCategoryIds, activeCategoryItems } = this.props;
    const renderSwiperItems = activeCategoryIds.map((id, index) => {
      return (
        <SwiperSlide key={id} tag={'li'}>
          <CategoryItem key={id} {...activeCategoryItems[id]} />
        </SwiperSlide>
      );
    });
    const renderItems = activeCategoryIds.map((id, index) => {
      return (
        <li className="swiper-slide" key={id}>
          <CategoryItem key={id} {...activeCategoryItems[id]} />
        </li>
      );
    });
    const direction = this.direction;
    const slidesPerView = direction === 'horizontal' ? 'auto' : activeCategoryIds.length;
    const params = {
      wrapperTag: 'ul',
      direction,
      slidesPerView,
      allowTouchMove: direction === 'horizontal',
      loop: false,
    };
    if (isRTL) {
      params.dir = 'rtl';
    }

    // Only using swiper for horizontal category nav
    return (
      <section className="gameCategorys">
        <div className="wrapper">
          <div className="category_list">
            {direction === 'horizontal' ? (
              <Swiper {...params}>{renderSwiperItems}</Swiper>
            ) : (
              <div className="swiper-container swiper-container-vertical">
                <div className="swiper-wrapper">{renderItems}</div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeCategoryIds: getActiveCategoryIds(state),
    activeCategoryItems: getActiveCategoryItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectSubCategory: (subCategoryId) => {
      dispatch(selectSubCategory(subCategoryId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
