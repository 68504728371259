import React, { Component } from 'react';
import CarouselRow from 'components/carousel/CarouselRow';
import CarouselRowNoSlider from 'components/carousel/CarouselRowNoSlider';

class CarouselBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isReady, blocks, copy, siteStyles, isSmartphone, ageRating, rightPadStyle, spinner, isRTL, carouselPlaceholder, carouselBreakpoints } = this.props; // passed through
    let previousColour = '';
    const renderCarousels = blocks.map((data, index) => {
      const first = index === 0;
      const last = index === blocks.length - 1; // Math.max(0, index === blocks.length - 1);
      const currentColour = data.colour;
      const nextColour = blocks[index + 1] && blocks[index + 1].colour;

      let isNewColourBlock = false;
      let isNewColourBlockEnd = false;

      if (previousColour !== currentColour) {
        previousColour = currentColour;
        isNewColourBlock = true;
      }
      if (currentColour !== nextColour) {
        isNewColourBlockEnd = true;
      }

      const opts = {
        key: data.id,
        isReady,
        copy,
        index,
        first,
        last,
        isNewColourBlock,
        isNewColourBlockEnd,
        siteStyles,
        ageRating,
        rightPadStyle,
        spinner,
        isRTL,
        carouselPlaceholder,
        carouselBreakpoints,
        ...data,
      };

      return data.itemsById.length > 0 && !isSmartphone ? <CarouselRow {...opts} /> : data.itemsById.length > 0 ? <CarouselRowNoSlider {...opts} /> : null;
      // return data.itemsById.length > 3 ? <CarouselRow {...opts} /> : data.itemsById.length > 0 ? <CarouselRowNoSlider {...opts} /> : null;
    });

    return <>{renderCarousels}</>;
  }
}

export default CarouselBlock;
