import { CAROUSELS_BOOTSTRAP, CAROUSELS_INIT, CAROUSELS_INVALIDATE, CAROUSELS_RECEIVE, CAROUSELS_REQUEST, CAROUSELS_RESET } from 'constants/Actions';

import { fetchCarouselService } from 'services/app';
import { parseEntry } from 'store/models/EntryParser';
import { getPageId } from 'store/app/appSelectors';

const _setInitialised = () => {
  return {
    type: CAROUSELS_INIT,
  };
};

const invalidate = (results) => {
  return {
    type: CAROUSELS_INVALIDATE,
    results,
  };
};

const resetEntries = () => {
  return {
    type: CAROUSELS_RESET,
  };
};

const requestEntries = () => {
  return {
    type: CAROUSELS_REQUEST,
  };
};

const receiveEntries = ({ blocks }) => {
  return {
    type: CAROUSELS_RECEIVE,
    blocks,
  };
};

const parseBlock = (block, boostrap) => {
  const { id, title, use_ribbon_override, ribbon_label, colour } = block.content_block;
  // console.log('parseBlock', block, block.items, 'isArray', Array.isArray(block.items));
  const ribbon = use_ribbon_override === '1';
  const blockTitle = ribbon ? ribbon_label : title;
  const blockColour = colour !== '' && colour !== null ? colour : 'purple';
  const items = boostrap
    ? [{ id: 'ph_01' }, { id: 'ph_02' }, { id: 'ph_03' }, { id: 'ph_04' }]
    : Array.isArray(block.items)
    ? block.items.map((item, i) => {
        return parseEntry(item.content_type, item[item.content_type] || item);
      })
    : [];
  return {
    id,
    title: blockTitle,
    ribbon,
    colour: blockColour,
    items,
  };
};

export const parseBlocks = (blocks, boostrap = false) => {
  return blocks.map((block, i) => {
    return parseBlock(block, boostrap);
  });
};

const _fetchEntries = (getState) => {
  const state = getState();
  const pageId = getPageId(state);
  return (dispatch) => {
    dispatch(requestEntries());
    fetchCarouselService(pageId)
      .then((data) => {
        dispatch(receiveEntries({ blocks: parseBlocks(data.blocks) }));
      })
      .catch((response) => {
        dispatch(invalidate({ response }));
        console.error(response);
      });
  };
};

const _bootstrapCarousels = (getState, contentBlocks) => {
  // parse initial content blocks for placeholders
  return {
    type: CAROUSELS_BOOTSTRAP,
    blocks: parseBlocks(contentBlocks, true),
  };
};

export const setInitialised = () => {
  return (dispatch, getState) => {
    return dispatch(_setInitialised());
  };
};

export const fetchCarousels = () => {
  return (dispatch, getState) => {
    return dispatch(_fetchEntries(getState));
  };
};

export const bootstrapCarousels = (contentBlocks) => {
  return (dispatch, getState) => {
    return dispatch(_bootstrapCarousels(getState, contentBlocks));
  };
};
