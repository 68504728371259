import React, { Component } from 'react';

/**
 * Used by Boing FR
 */

class ScriptRawAd extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    const { scriptURL } = this.props;
    /**
     * https://medium.com/@garrettmac/reactjs-how-to-safely-manipulate-the-dom-when-reactjs-cant-the-right-way-8a20928e8a6
     * https://stackoverflow.com/questions/35614809/react-script-tag-not-working-when-inserted-using-dangerouslysetinnerhtml
     *
     * <ins data-zone="31883" class="byadthink"></ins>
     * <script async src="//ad.adxcore.com/adjs_r.php?async&amp;what=zone:31883&amp;inf=no"></script>
     */
    if (scriptURL) {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      script.type = 'text/javascript';
      this.instance.appendChild(script);
    }

    // TR-56
    window.initTagGpt && window.initTagGpt();
  }

  render() {
    const { tag } = this.props;
    return <div className="lazy-load-mpu-2" ref={(el) => (this.instance = el)} dangerouslySetInnerHTML={{ __html: tag }} />;
  }
}
export default ScriptRawAd;
