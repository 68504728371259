import { CONTENT_COUNT_RECEIVE, FILTER_RESET, FILTER_SELECT, FILTERS_CREATE } from 'constants/Actions';
import { FILTERS } from 'store/filters/filtersFactory';

const initState = {
  filterOptionsByID: {},
  filterOptionsAllIDs: [],
  selectedFilterId: null,
};

const removeFilterByContentCount = (state, totals) => {
  const HAS_APPS = totals.app > 0;
  const HAS_WEB_GAMES = totals.web_game > 0;
  const HAS_GAMES = HAS_APPS || HAS_WEB_GAMES;
  const HAS_VIDEOS = totals.video > 0;
  const HAS_COMICS = totals.comic > 0;
  const HAS_EDITORIALS = totals.editorial && totals.editorial > 0;
  const HAS_QUIZZES = totals.quiz > 0;
  const HAS_QUIZ_PERSONALITY = totals.quiz_personality > 0;
  const HAS_QUIZ_POINTS = totals.quiz_points > 0;
  const HAS_QUIZ_POLL = totals.quiz_poll > 0;
  const HAS_APP_IOS = totals.app_ios > 0;
  const HAS_APP_ANDROID = totals.app_android > 0;

  const activeCategorCount = ['app', 'comic', 'quiz', 'video', 'web_game', 'editorial'].reduce((acc, val) => {
    acc = totals[val] > 0 ? acc + 1 : acc;
    return acc;
  }, 0);
  return state.filterOptionsAllIDs.reduce((acc, id, i) => {
    if (id === FILTERS.NEW || id === FILTERS.AZ || id === FILTERS.ZA) {
      acc.push(id);
    }
    if (id === FILTERS.ALL && activeCategorCount > 1) {
      /*
       * only add FILTERS.ALL if there are 2 or more categories with items
       */
      acc.push(id);
    }
    if (HAS_APPS && ((HAS_APP_IOS && id === FILTERS.IOS) || (HAS_APP_ANDROID && id === FILTERS.ANDROID))) {
      acc.push(id);
    }
    if (HAS_WEB_GAMES && HAS_APPS && id === FILTERS.WEB) {
      // only show this if user can switch between web_games and ios/android
      acc.push(id);
    }
    if (HAS_QUIZZES && ((HAS_QUIZ_PERSONALITY && id === FILTERS.PERSONALITY) || (HAS_QUIZ_POINTS && id === FILTERS.POINTS) || (HAS_QUIZ_POLL && id === FILTERS.POLL))) {
      acc.push(id);
    }
    if (
      (HAS_GAMES && id === FILTERS.GAMES) ||
      (HAS_VIDEOS && id === FILTERS.VIDEOS) ||
      (HAS_COMICS && id === FILTERS.COMICS) ||
      (HAS_QUIZZES && id === FILTERS.QUIZZES) ||
      (HAS_EDITORIALS && id === FILTERS.EDITORIALS)
    ) {
      acc.push(id);
    }
    return acc;
  }, []);
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case FILTERS_CREATE:
      nextState.filterOptionsByID = action.filterOptionsByID;
      nextState.filterOptionsAllIDs = action.filterOptionsAllIDs;
      nextState.selectedFilterId = action.selectedFilterId;
      return { ...state, ...nextState };
    case CONTENT_COUNT_RECEIVE:
      nextState.filterOptionsAllIDs = removeFilterByContentCount(state, action.contentCount);
      if (!nextState.filterOptionsAllIDs.includes(state.selectedFilterId)) {
        nextState.selectedFilterId = nextState.filterOptionsAllIDs[0]; // reset filter to first in list if no match
      }
      return { ...state, ...nextState };
    case FILTER_RESET:
      nextState.selectedFilterId = state.filterOptionsAllIDs[0];
      return { ...state, ...nextState };
    case FILTER_SELECT:
      nextState.selectedFilterId = action.filterId;
      return { ...state, ...nextState };
    default:
      return state;
  }
};

export default reducer;
