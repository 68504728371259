import React from 'react';

const CarouselError = () => {
  return (
    <>
      <section className="block-list block-list--first">
        <div className="wrapper b_white wrapper--radius-top" />
        <div className="wrapper b_white">
          <h2>Ooops!! Something has gone wrong</h2>
        </div>
        <div className="wrapper b_white wrapper--radius-bottom" />
      </section>
    </>
  );
};
export default CarouselError;
