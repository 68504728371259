import React from 'react';
import { render } from 'react-dom';
import './../scss/main.scss';
import App from './App';
import 'intersection-observer'; // polyfill for react-lazy-images
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { DOM_ID } from 'constants/App';

const store = configureStore();

const doRender = (Component = App, el) => {
  render(
    <Provider store={store}>
      <Router basename={'/'}>
        <Route component={App} store={store} />
      </Router>
    </Provider>,
    el
  );
};

window.addEventListener('load', () => {
  const el = document.getElementById(DOM_ID);
  if (el === null) {
    console.warn(`Dom node ${DOM_ID} is missing - aborting app`);
  } else {
    doRender(App, el);
  }
});

if (process.env.NODE_ENV !== 'production') {
  module.hot.accept('./App', () => {
    const NextRootContainer = require('./App').default;
    doRender(NextRootContainer);
  });
}
