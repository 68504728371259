import React from 'react';

export const IntroductionText = ({ introductionText }) => {
  return introductionText ? (
    <>
      <section className="introduction-text">
        <div className="wrapper b_white wrapper--radius-top wrapper--radius-bottom" dangerouslySetInnerHTML={{ __html: introductionText }}></div>
      </section>
    </>
  ) : null;
};
