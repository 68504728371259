import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppRouter from 'AppRouter';
import { fetchFilters } from 'store/filters/filtersActions';
import { bootstrapFeature } from 'store/feature/featureActions';
import { bootstrapAds } from 'store/ads/adsActions';
import { getContentCount } from 'store/entries/entriesActions';
import { bootstrapCategories } from 'store/category/categoryActions';
import { bootstrapApp } from 'store/app/appActions';
import {
  ADS_DATA,
  CATEGORY_DATA,
  RELATED_ITEMS,
  CONTENT_BLOCKS,
  DEVICE,
  FEATURE_BLOCK,
  FEATURE_POSITION_IN_CAROUSELS,
  FILTER_OPTIONS,
  FILTERS_ACTIVE,
  getSiteStyles,
  HAS_CAROUSELS,
  HAS_CONTENT_BLOCK,
  ICON_AGE_RATING,
  ICON_ALTS,
  PAGE_ID,
  PLACEHOLDER,
  RENDER_AGE_RATING,
  RENDER_FEATURE_BLOCK,
  SPINNER,
  IS_RTL,
} from 'constants/App';
import { bootstrapCarousels } from 'store/carousels/carouselsActions';
import { bootstrapRelatedItems } from 'store/relatedItems/relatedActions';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.bootstrapApp();
    this.props.bootstrapCarousels();
    this.props.bootstrapAds();
    this.props.fetchFilters();
    this.props.bootstrapFeature();
    this.props.getContentCount();
    this.props.bootstrapCategories();
    this.props.bootstrapRelatedItems();
  }

  render() {
    return (
      <>
        <AppRouter />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    bootstrapApp: () => {
      const ageRating = {
        render: RENDER_AGE_RATING,
        icon: ICON_AGE_RATING,
        iconAlt: ICON_ALTS.AGE,
      };
      const carouselPlaceholder = {
        img: PLACEHOLDER,
        imgAlt: ICON_ALTS.PLACEHOLDER,
      };
      const opts = {
        pageId: PAGE_ID,
        hasCarousels: HAS_CAROUSELS,
        hasContentBlock: HAS_CONTENT_BLOCK,
        hasFeatures: RENDER_FEATURE_BLOCK,
        featureInCarousel: FEATURE_POSITION_IN_CAROUSELS,
        filtersActive: FILTERS_ACTIVE,
        siteStyles: getSiteStyles(),
        ageRating,
        deviceType: DEVICE,
        spinner: SPINNER,
        isRTL: IS_RTL,
        carouselPlaceholder,
        contentBlocks: CONTENT_BLOCKS,
        categoryData: CATEGORY_DATA,
      };
      dispatch(bootstrapApp(opts));
    },
    bootstrapCarousels: () => {
      dispatch(bootstrapCarousels(CONTENT_BLOCKS));
    },
    bootstrapAds: () => {
      dispatch(bootstrapAds(ADS_DATA));
    },
    fetchFilters: () => {
      dispatch(fetchFilters(FILTER_OPTIONS));
    },
    bootstrapFeature: () => {
      dispatch(bootstrapFeature(FEATURE_BLOCK));
    },
    getContentCount: () => {
      dispatch(getContentCount());
    },
    bootstrapCategories: () => {
      dispatch(bootstrapCategories(CATEGORY_DATA));
    },
    bootstrapRelatedItems: () => {
      dispatch(bootstrapRelatedItems(RELATED_ITEMS));
    },
  };
}
export default connect(null, mapDispatchToProps)(App);
