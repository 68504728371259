import React, { Component } from 'react';

class ScriptAd extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { id, scriptURL } = this.props;
    const script = `<script src='${scriptURL}'></script>`;
    window.postscribe && window.postscribe('#' + id, script);
  }

  render() {
    const { id } = this.props;
    return <div id={id} style={{ textAlign: 'center', margin: '0 auto' }} />;
  }
}
export default ScriptAd;
