import React, { Component } from 'react';

class EnsightenAd extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { trigger } = this.props;
    if (window.ensBootstraps && window.ensBootstraps.tdi) {
      if (window.ensBootstraps.tdi.ensEvent) {
        window.ensBootstraps.tdi.ensEvent.trigger(trigger);
      } else {
        console.info('EnsightenAd - Event trigger not available');
      }
    } else {
      console.info('EnsightenAd - Bootstrapper not available');
    }
  }

  render() {
    const { id } = this.props;
    return <div id={id} />;
  }
}
export default EnsightenAd;
