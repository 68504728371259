import { PAGE, SUB_PAGE } from 'constants/Const';

export const getBootPageId = (state) => {
  return state.app.bootPageId;
};

export const getHasActiveCategorys = (state) => {
  return state.app.hasActiveCategorys;
};

export const getCarouselBreakpoints = (state) => {
  return getHasActiveCategorys(state)
    ? {
        1168: { slidesPerView: 4 }, // when window width is >= 1168 px
        767: { slidesPerView: 3 }, // when window width is >= 767 px
        320: { slidesPerView: 2 }, // when window width is >= 320 px
        1: { slidesPerView: 1 }, // when window width is >= 1 px
      }
    : {
        767: { slidesPerView: 3 }, // when window width is >= 767 px
        320: { slidesPerView: 2 }, // when window width is >= 320 px
        1: { slidesPerView: 1 }, // when window width is >= 1 px
      };
};

export const getContentBlockIsFourUp = (state) => {
  return getHasActiveCategorys(state) ? true : false;
};

export const getHasContentBlock = (state) => {
  return state.app.hasContentBlock;
};

export const getHasCarousels = (state) => {
  return state.app.hasCarousels && !state.app.isGameCategoryPage; // disable carousels on game category page
};

export const getHasFeatures = (state) => {
  return state.app.hasFeatures;
};

export const getHasFeatureInCarousel = (state) => {
  return state.app.featureInCarousel;
};

export const getShowFilterDrop = (state) => {
  return state.app.filtersActive && getPageId(state) !== PAGE.APPS;
};

export const getSiteStyles = (state) => {
  return state.app.siteStyles;
};

export const getAgeRating = (state) => {
  return state.app.ageRating;
};

export const getDeviceIsSmartphone = (state) => {
  return state.app.deviceType === 'smartphone';
};

export const getIsRTL = (state) => {
  return state.app.isRTL;
};

export const getRightPadStyle = (state) => {
  return state.app.rightPadStyle;
};

export const getSpinner = (state) => {
  return state.app.spinner;
};

export const getCarouselPlaceholder = (state) => {
  return state.app.carouselPlaceholder;
};

export const getContentBlocks = (state) => {
  return state.app.contentBlocks;
};

export const getisGamePage = (state) => {
  return state.app.isGamePage;
};

export const getIsGameCategoryPage = (state) => {
  return state.app.isGameCategoryPage;
};

export const getIsGameOrGameCategoryPage = (state) => {
  return state.app.isGamePage || state.app.isGameCategoryPage;
};

export const getPageId = (state) => {
  const isGamePage = getisGamePage(state);
  const isGameCategoryPage = getIsGameCategoryPage(state);
  const bootPageId = state.app.bootPageId;

  if (!isGamePage && !isGameCategoryPage) {
    return bootPageId;
  }
  if (isGamePage) {
    return PAGE.GAMES;
  }
  if (isGameCategoryPage) {
    return SUB_PAGE.WEB_GAME_CATEGORY;
  }
};
