import { CATEGORY_BOOTSTRAP, CATEGORY_FEATURED, SUBCATEGORY_CLEAR, SUBCATEGORY_SELECT } from 'constants/Actions';
import { triggerEnsightenTrackingCategoryInteraction } from 'constants/EnsightenTracking';
import { getCategoryInteractionTrackingData } from 'store/category/categorySelectors';
import { LOG } from 'constants/App';

const _bootstrapCategories = (categoryDataAll, categoryDataPage, isGameCategoryPage) => {
  const allCategorysIds = categoryDataAll.map((category, index) => {
    return category.id;
  });

  // LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ categoryDataAll ::`, LOG.clrGreen, categoryDataAll);

  const allCategorysById = categoryDataAll.reduce((acc, category, index) => {
    acc[category.id] = {
      ids: category.items.reduce((acc, subCategory) => {
        subCategory.friendly_url && acc.push(subCategory.id); // only use categorys with friendly urls
        return acc;
      }, []),
      items: category.items.reduce((acc, subCategory) => {
        if (subCategory.featured) {
          /*
           * Parse the 'featured' category differently
           * preselected on games page | returns to games from games category page
           */
          const { id: subCategoryId, name: categoryLabel, friendly_url: friendlyURL, englishname = 'nvs', blockTitle = '' } = subCategory;
          acc[subCategory.id] = { subCategoryId, friendlyURL, categoryLabel, englishname, pageTitle: categoryLabel, blockTitle };
        } else {
          const { id: subCategoryId, name: pageName, title: pageTitle, friendly_url: friendlyURL, web_game_category = {}, data } = subCategory;
          const { englishname = 'nvs' } = data;
          const { id: apiId, name: categoryLabel } = web_game_category;
          const { content_block_title: blockTitle } = data;
          acc[subCategory.id] = { subCategoryId, pageName, pageTitle, friendlyURL, apiId, categoryLabel, blockTitle, englishname };
        }
        return acc;
      }, {}),
    };
    return acc;
  }, {});

  const activeCategoryId = allCategorysIds[0]; // eg: game
  let selectedSubCategoryId;
  if (isGameCategoryPage) {
    // page load on game category page : use page data
    const { id: subCategoryId } = categoryDataPage;
    selectedSubCategoryId = subCategoryId;
  } else {
    // defaults to featured category
    selectedSubCategoryId = CATEGORY_FEATURED;
  }

  // LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ allCategorysById ::`, LOG.clrGreen, allCategorysById);
  return {
    type: CATEGORY_BOOTSTRAP,
    allCategorysIds,
    allCategorysById,

    activeCategoryId,
    selectedSubCategoryId,
  };
};

const _clearSubCategory = () => {
  return {
    type: SUBCATEGORY_CLEAR,
  };
};

const _selectSubCategory = (subCategoryId) => {
  return {
    type: SUBCATEGORY_SELECT,
    subCategoryId,
  };
};

export const clearSubCategory = () => {
  // LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ clearSubCategory`, LOG.clrGreen);
  return (dispatch, getState) => {
    dispatch(_clearSubCategory());
  };
};

export const selectSubCategory = (subCategoryId) => {
  LOG.enabledStore && console.log(`%c@@@@@@@@@@@@@@@ selectSubCategory :: ${subCategoryId}`, LOG.clrGreen);
  return (dispatch, getState) => {
    const trackingData = getCategoryInteractionTrackingData(getState(), subCategoryId);
    trackingData && triggerEnsightenTrackingCategoryInteraction(trackingData);
    dispatch(_selectSubCategory(subCategoryId));
  };
};

export const bootstrapCategories = ({ categoryDataAll, categoryDataPage, isGameCategoryPage }) => {
  return (dispatch) => {
    return dispatch(_bootstrapCategories(categoryDataAll, categoryDataPage, isGameCategoryPage));
  };
};
