import { PAGE } from 'constants/Const';
import { ADS_BOOTSTRAP } from 'constants/Actions';
import * as ADS from 'constants/Ads';
import { getPageId } from 'store/app/appSelectors';

const bootstrap = (state, pageId, adsData) => {
  let adtech_mpu_src = adsData.adtech_mpu_code;
  let adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile;
  switch (pageId) {
    case PAGE.GAMES:
      adtech_mpu_src = adsData.adtech_mpu_code_games;
      adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile_games;
      break;
    case PAGE.VIDEOS:
      adtech_mpu_src = adsData.adtech_mpu_code_videos;
      adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile_videos;
      break;
    case PAGE.COMICS:
      adtech_mpu_src = adsData.adtech_mpu_code_comics;
      adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile_comics;
      break;
    case PAGE.ANIMALS:
      adtech_mpu_src = adsData.adtech_mpu_code_animals;
      adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile_animals;
      break;
    case PAGE.LETSCREATE:
      adtech_mpu_src = adsData.adtech_mpu_code_letscreate;
      adtech_mpu_src_mobile = adsData.adtech_mpu_code_mobile_letscreate;
      break;
  }
  let adType;
  switch (parseInt(adsData.header_ads_active)) {
    case 0:
      adType = ADS.DISABLED;
      break;
    case 2: // script
      adType = ADS.SCRIPT;
      break;
    case 3:
      adType = ADS.ADTECH;
      break;
    case 4: // raw script
    case 10: // raw script
      adType = ADS.SCRIPT_RAW;
      break;
    case 7:
      adType = ADS.DOUBLECLICK;
      break;
    case 8:
      adType = ADS.ENSIGHTEN;
      break;
    case 9:
      adType = ADS.DOUBLECLICK_ES;
      break;
  }

  return {
    type: ADS_BOOTSTRAP,
    data: adsData,
    adTechDesktopTag: adtech_mpu_src,
    adTechMobileTag: adtech_mpu_src_mobile,
    adType,
  };
};

export const bootstrapAds = (adsData) => {
  return (dispatch, getState) => {
    const state = getState();
    const pageId = getPageId(state);
    return dispatch(bootstrap(state, pageId, adsData));
  };
};
