import React from 'react';
import { LazyImage } from 'react-lazy-images';
import { processImages } from 'utils/general';

/*
 * react-lazy-images : probably time to switch this out!!
 *
 * No Picture support
 * https://github.com/fpapado/react-lazy-images/issues/9
 *
 * Double load:
 * https://github.com/fpapado/react-lazy-images/issues/32
 *
 * 404 Error
 * https://github.com/fpapado/react-lazy-images/issues/17
 *
 */
export const LazyImageWithPlaceholder = (props) => {
  const { alt, imageData } = props;
  let { placeholder, small, medium, large } = imageData;

  const { placeholder_lazy, srcSet, src } = processImages(placeholder, [small, medium, large]);

  return (
    <LazyImage
      src={src}
      srcSet={srcSet}
      alt={alt}
      debounceDurationMs={500}
      placeholder={({ imageProps, ref }) => <img ref={ref} className="sl-img radius-top" src={placeholder_lazy} alt={imageProps.alt} />}
      actual={({ imageProps }) => <img {...imageProps} className="sl-img radius-top animated fadeIn" />}
      error={() => <img className="sl-img radius-top" src={placeholder_lazy} alt={alt} />}
    />
  );
};
