import React from 'react';

const CarouselItemPlaceholder = (opts) => {
  const { spinner, carouselPlaceholder } = opts;
  return (
    <div className="carousel_row_placeholder">
      <div className="content_item">
        <div className="placeholder-loading">
          <img className="icon icon_spin" src={spinner} alt="loading icon" />
        </div>
      </div>
    </div>
  );
};
export default CarouselItemPlaceholder;
