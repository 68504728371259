import { DEVICE } from 'constants/App';
import { PAGE, SUB_PAGE } from 'constants/Const';
import { getPageId } from 'store/app/appSelectors';

export const getAdType = (state) => {
  return state.ads.adType;
};

export const getDoubleclickID = (state) => {
  return 'div-gpt-ad-' + state.ads.data.doubleclick_gpt_ad_id + '-3';
};

export const getDoubleclickESID = (state) => {
  /*
   * The feature block mpu is tagged as mpu2ID on homepage,games,videos,shows pages
   * On the videoplayer page or gameplay page this needs to be tagged as mpu1ID : see /views/default/components/view_specific/header/doubleclick_gpt_es.php
   */
  const pageId = getPageId(state);
  const tag = pageId === SUB_PAGE.GAMEPLAY || pageId === SUB_PAGE.VIDEOPLAYER ? '-mpu1' : '-mpu2';
  return 'div-gpt-ad-' + state.ads.data.doubleclick_gpt_es_ad_id + tag;
};

export const getAdTechTag = (state) => {
  return DEVICE === 'smartphone' ? state.ads.adTechMobileTag : state.ads.adTechDesktopTag;
};

export const getScriptURL = (state) => {
  return state.ads.data.mpu_ads_code;
};

export const getRawScriptTag = (state) => {
  return DEVICE === 'smartphone' ? state.ads.data.mpu_ads_code_mobile : state.ads.data.mpu_ads_code;
};

export const getRawScriptTagFeature = (state) => {
  return DEVICE === 'smartphone' ? state.ads.data.feature_mpu_ads_code_mobile : state.ads.data.feature_mpu_ads_code;
};

export const getEnsightenID = (state) => {
  return state.ads.data.ensighten_dfp_id_4;
};

export const getEnsightenTrigger = (state) => {
  const pageId = getPageId(state);
  switch (pageId) {
    case PAGE.HOME:
      return state.ads.data.ensighten_dfp_trigger_homepage;
    case PAGE.GAMES:
      return state.ads.data.ensighten_dfp_trigger_gamespage;
    case SUB_PAGE.GAMEPLAY:
      return state.ads.data.ensighten_dfp_trigger_gamepage;
    case PAGE.VIDEOS:
      return state.ads.data.ensighten_dfp_trigger_videospage;
    case SUB_PAGE.VIDEOPLAYER:
      return state.ads.data.ensighten_dfp_trigger_videopage;
    default:
      return state.ads.data.ensighten_dfp_trigger_otherpage;
  }
};
