export const FILTERS = {
  NEW: 'allnew',
  AZ: 'a-z',
  ZA: 'z-a',
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
  PERSONALITY: 'personality',
  POINTS: 'points',
  POLL: 'poll',
  ALL: 'shows_all',
  GAMES: 'games',
  VIDEOS: 'videos',
  QUIZZES: 'quizzes',
  COMICS: 'comics',
  EDITORIALS: 'editorials',
};

import { getCopy } from 'store/copy/copySelectors';

export const createFilter = (state, id, contentType) => {
  let filter = {};
  switch (id) {
    case FILTERS.NEW: {
      const label = getCopy(state, `${contentType}_sort_new_txt`, 'games_sort_new_txt');
      filter = { id, label, value: id, filter: { by: 'published_date', direction: 'DESC' } };
      break;
    }
    case FILTERS.AZ: {
      const label = getCopy(state, `${contentType}_sort_az_txt`, 'games_sort_az_txt');
      filter = { id, label, value: id, filter: { by: 'name', direction: 'ASC' } };
      break;
    }
    case FILTERS.ZA: {
      const label = getCopy(state, `${contentType}_sort_za_txt`, 'games_sort_za_txt');
      filter = { id, label, value: id, filter: { by: 'name', direction: 'DESC' } };
      break;
    }
    case FILTERS.WEB: {
      const label = getCopy(state, `${contentType}_all_web_games_txt`, 'games_all_web_games_txt');
      filter = { id, label, value: id, filter: { type: 'web' } };
      break;
    }
    case FILTERS.IOS: {
      const label = getCopy(state, `${contentType}_all_ios_txt`, 'games_all_ios_txt');
      filter = { id, label, value: id, filter: { type: 'ios' } };
      break;
    }
    case FILTERS.ANDROID: {
      const label = getCopy(state, `${contentType}_all_android_txt`, 'games_all_android_txt');
      filter = { id, label, value: id, filter: { type: 'android' } };
      break;
    }
    case FILTERS.PERSONALITY: {
      const label = getCopy(state, `${contentType}_sort_personality_txt`, 'quizzes_sort_personality_txt');
      filter = { id, label, value: id, filter: { type: 'personality' } };
      break;
    }
    case FILTERS.POINTS: {
      const label = getCopy(state, `${contentType}_sort_points_txt`, 'quizzes_sort_points_txt');
      filter = { id, label, value: id, filter: { type: 'points' } };
      break;
    }
    case FILTERS.POLL: {
      const label = getCopy(state, `${contentType}_sort_poll_txt`, 'quizzes_sort_poll_txt');
      filter = { id, label, value: id, filter: { type: 'poll' } };
      break;
    }
    case FILTERS.ALL: {
      const label = getCopy(state, `${contentType}_sort_all`, 'shows_sort_all');
      filter = { id, label, value: id, filter: { type: 'shows_all' } };
      break;
    }
    case FILTERS.GAMES: {
      const label = getCopy(state, `${contentType}_sort_games`, 'shows_sort_games');
      filter = { id, label, value: id, filter: { type: 'web' } };
      break;
    }
    case FILTERS.VIDEOS: {
      const label = getCopy(state, `${contentType}_sort_videos`, 'shows_sort_videos');
      filter = { id, label, value: id, filter: { type: 'video' } };
      break;
    }
    case FILTERS.QUIZZES: {
      const label = getCopy(state, `${contentType}_sort_quizzes`, 'shows_sort_quizzes');
      filter = { id, label, value: id, filter: { type: 'quiz' } };
      break;
    }
    case FILTERS.COMICS: {
      const label = getCopy(state, `${contentType}_sort_comics`, 'shows_sort_comics');
      filter = { id, label, value: id, filter: { type: 'comic' } };
      break;
    }
    case FILTERS.EDITORIALS: {
      const label = getCopy(state, `${contentType}_sort_editorials`, 'shows_sort_editorials');
      filter = { id, label, value: id, filter: { type: 'editorial' } };
      break;
    }
  }
  // console.log('createFilter', contentType, filter);
  return filter;
};
