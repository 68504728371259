import Loader from 'containers/Loader';
import React from 'react';

export const Loading = ({ spinner, wrapClass, pageTitle, moreButton, featureBlocks, contentBlockStyle }) => {
  return (
    <>
      <section className="block-list block-list-filter simpleblocks">
        <div className={wrapClass}>
          <h2 className="cbtitle">{pageTitle}</h2>
          <ul className={contentBlockStyle}>
            <Loader spinner={spinner} isVisible={true} />
          </ul>
          {moreButton}
        </div>
      </section>
      {featureBlocks}
    </>
  );
};
