import React, { Component } from 'react';
import { LOG } from 'constants/App';

class AdtechAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialised: null,
      tag: '',
    };
  }

  componentDidMount() {
    const { tag } = this.props;
    const { initialised } = this.state;
    this.setState({ initialised: true });
    if (!initialised) {
      if (window.ADTECH) {
        const delay = setTimeout(() => {
          window.ADTECH.config.placements[tag] = {
            adContainerId: 'feature_block_mpu',
            // params: {},
            complete: (e) => {
              LOG.enabled && console.log('%c++++++ [AdtechAd] COMPLETE', LOG.clrBlack);
            },
          };
          window.ADTECH.enqueueAd(parseInt(tag));
          window.ADTECH.executeQueue();
        }, 200);
      }
    }
  }

  render() {
    const { initialised } = this.state;
    return <div className="adtech_dsc3874293" id="feature_block_mpu" />;
  }
}
export default AdtechAd;
