// Setup const to know if we need to be using Ensighten 'New' tracking
import { CATEGORY_FEATURED } from 'constants/Actions';

export const ENSIGHTEN_NEW = typeof window.ensightenTracking != 'undefined' && window.ensightenTracking.type == 'new' ? true : false;

export const getDataLayer = () => {
  const { feedcode, previouspage, screenorientation, ...baseData } = window.trackingVariables;
  return baseData;
};

// Data sets to be used for tracking
export const INTERACTION_FEATURED_DATA = {
  interaction: 'featured thumbnail click',
  featuredcontent: '',
  featuredcontentevent: 1,
};

export const INTERACTION_WEB_GAME_DATA = {
  franchise: '',
  gametitle: '',
  englishname: '',
  interaction: 'game thumbnail click',
  interactionevent: 1,
  gameclickedevent: 1,
};

export const INTERACTION_VIDEO_DATA = {
  franchise: '',
  videotitle: '',
  englishname: '',
  interaction: 'video thumbnail click',
  interactionevent: 1,
  videoclickedevent: 1,
};

export const PAGEVIEW_CATEGORY_DATA = {
  ensighten: 'nvs',
  version: 'nvs',
  pageviewevent: 1,
};

export const INTERACTION_CATEGORY_DATA = {
  englishname: 'nvs',
  featuredcontent: 'nvs',
  ensighten: 'nvs',
  version: 'nvs',
  interactionevent: 1,
};

export const track = function (props) {
  const { content_type } = props;
  if (ENSIGHTEN_NEW) {
    switch (content_type) {
      case 'app':
      case 'web_game':
        triggerEnsightenTrackingWebGame(props);
        break;
      case 'video':
        triggerEnsightenTrackingVideo(props);
        break;
      default:
        break;
    }
  }
};

export const triggerEnsightenTrackingFeature = function (props) {
  const { englishname, title = '', showdata } = props;
  const { name_english } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_FEATURED_DATA, ...dataLayer };
    data.featuredcontent = title.toLowerCase();
    data.franchise = franchise;
    data.contenttype = 'featured content';
    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingWebGame = function (props) {
  const { englishname, title = '', showdata, description } = props;
  const { name_english } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_WEB_GAME_DATA, ...dataLayer };
    data.gametitle = description.toLowerCase();
    data.englishname = englishname ? englishname.toLowerCase() : title.toLowerCase();
    data.franchise = franchise;
    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * contenttitle is clicked content, not current page title
     */
    data.contenttitle = data.gametitle;
    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingVideo = function (props) {
  // console.log('triggerEnsightenTrackingVideo', JSON.stringify(props));
  const { englishname, title = '', showdata, description } = props;
  const { name_english } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_VIDEO_DATA, ...dataLayer };
    data.videotitle = description.toLowerCase();
    data.videocategory = 'nvs';
    data.englishname = englishname ? englishname.toLowerCase() : title.toLowerCase();
    data.franchise = franchise;
    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * contenttitle is clicked content, not current page title
     */
    data.contenttitle = data.videotitle;
    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

const updateDataBySubCategory = (data, subCategory) => {
  let pageName = data.pageName;
  if (subCategory === CATEGORY_FEATURED) {
    // we are returning to main games page
    data.subsection = 'games|main';
    data.contenttype = 'game';
    if (pageName.includes('category')) {
      // remove category data, restoring to base pageName
      const ary = pageName.split('/category');
      pageName = ary[0];
    }
  } else {
    // we are in game category page
    data.subsection = 'games|category';
    data.contenttype = 'section front';
    if (pageName.includes('category')) {
      // replace existing category
      const ary = pageName.split('/');
      ary.pop();
      pageName = ary.join('/') + '/' + subCategory.toLowerCase();
    } else {
      // add selected category to base pageName
      pageName = pageName + '/category/' + subCategory.toLowerCase();
    }
  }
  data.pageName = pageName;
};

export const triggerEnsightenTrackingCategoryInteraction = function (props) {
  const { category, subCategory, nextSubCategory } = props;
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_CATEGORY_DATA, ...dataLayer };
    updateDataBySubCategory(data, subCategory);
    data.contenttitle = data.contenttitle === '' ? 'nvs' : data.contenttitle;
    data.interaction = `${category} category filter`;
    if (category === 'game') {
      data.gamecategoryfilter = nextSubCategory.toLowerCase();
      data.gamefilterclickevent = 1;
    }
    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingCategoryPageView = function (props) {
  const { category, subCategory } = props;
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...PAGEVIEW_CATEGORY_DATA, ...dataLayer };
    updateDataBySubCategory(data, subCategory);
    data.contenttitle = data.contenttitle === '' ? 'nvs' : data.contenttitle;
    if (category === 'game' && subCategory !== CATEGORY_FEATURED) {
      data.gamecategorypage = 1;
      data.gamecategory = subCategory.toLowerCase();
    }
    turner_metadata.trackAction.push({ type: 'pageview', data });
  }
};
