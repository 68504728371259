import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import CarouselItemOther from 'components/carousel/CarouselItemOther';
import CarouselItemApp from 'components/carousel/CarouselItemApp';
import { Title } from 'components/carousel/Title';
import { USE_WEBP } from 'constants/App';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

class RelatedItemsCarouselRowNoSlider extends Component {
  constructor(props) {
    super(props);
  }

  renderItems({ blocks, isNewLabel, ageRating }) {
    let blockCount = 0;
    const carouselItemClass = 'carousel_item platform_all';
    return blocks.map((data, index) => {
      blockCount++;
      if (data.platforms) {
        return <CarouselItemApp key={data.id} isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass={carouselItemClass} />;
      } else {
        return <CarouselItemOther key={data.id} isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass={carouselItemClass} />;
      }
    });
  }

  render() {
    const { copy, title, blocks, ageRating, hasWrapper, wrapperClass, wrapperStyle } = this.props; // passed through
    const { home_new_content_title: isNewLabel } = copy;

    const extraClass = USE_WEBP ? 'webp' : 'non-webp';
    const carouselItems = this.renderItems({ blocks, isNewLabel, ageRating });
    const blockCount = carouselItems.length;

    const portalNode = document.getElementById('related-items');

    const els =
      !blockCount > 0 || !portalNode ? null : (
        <ConditionalWrapper
          condition={hasWrapper}
          wrapper={(children) => (
            <div className={wrapperClass} style={wrapperStyle}>
              {children}
            </div>
          )}
        >
          {<Title ribbon={false} title={title} extraClass={extraClass} />}
          <div className="carousel_widget three_onscreen related_items">
            <div className="carousel_area">
              <div className="carousel_wrapper_ignore">{carouselItems}</div>
            </div>
          </div>
        </ConditionalWrapper>
      );
    return els ? ReactDOM.createPortal(els, portalNode) : null;
  }
}

export default RelatedItemsCarouselRowNoSlider;
