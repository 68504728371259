import React, { Component } from 'react';

/**
 * Used by Boing IT
 */

class ScriptRawAd extends Component {
  constructor(props) {
    super(props);
    // this.domRef = React.createRef();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    // const { tag } = this.props;
    // $('.lazy-mpu-replace-2')
    //   .parent()
    //   .append(tag);

    // TR-56
    window.initTagGpt && window.initTagGpt();
  }

  render() {
    const { tag } = this.props;
    /**
     * Contents of tag is what needs to be rendered : <div id="adv-gpt-box-container1"></div>
     * https://medium.com/@garrettmac/reactjs-how-to-safely-manipulate-the-dom-when-reactjs-cant-the-right-way-8a20928e8a6
     * https://stackoverflow.com/questions/35614809/react-script-tag-not-working-when-inserted-using-dangerouslysetinnerhtml
     */

    /*
     * TODO : This doesn't appear to be working - aysnc issue maybe.
     */
    // console.log('ScriptRawAd.render tag', tag, ' | id', id);
    // return <div class="lazy-mpu-replace-2" ref={this.domRef} />;
    // return <div className="lazy-load-mpu-2" ref={(el) => (this.instance = el)} dangerouslySetInnerHTML={{ __html: tag }} />;
    return (
      <>
        <div className="lazy-load-mpu-2" />
        <div ref={(el) => (this.instance = el)} dangerouslySetInnerHTML={{ __html: tag }} />
      </>
    );
  }
}
export default ScriptRawAd;
