import { APP_BOOTSTRAP, APP_UPDATE_PAGE_ID } from 'constants/Actions';

const initState = {
  hasContentBlock: false,
  hasCarousels: false,
  hasFeatures: false,
  featureInCarousel: false,
  filtersActive: false,
  siteStyles: {},
  ageRating: { render: false, icon: '', iconAlt: '' },
  deviceType: null,
  isRTL: false,
  rightPadStyle: {},
  spinner: '',
  carouselPlaceholder: {},
  contentBlocks: [],
  hasActiveCategorys: false,
  isGamePage: false,
  isGameCategoryPage: false,
  pathname: null,
  bootPageId: null,
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case APP_BOOTSTRAP:
      nextState.hasContentBlock = action.hasContentBlock;
      nextState.hasCarousels = action.hasCarousels;
      nextState.hasActiveCategorys = action.hasActiveCategorys;
      nextState.hasFeatures = action.hasFeatures;
      nextState.featureInCarousel = action.featureInCarousel;
      nextState.filtersActive = action.filtersActive;
      nextState.siteStyles = action.siteStyles;
      nextState.ageRating = action.ageRating;
      nextState.deviceType = action.deviceType;
      nextState.isRTL = action.isRTL;
      nextState.rightPadStyle = action.rightPadStyle;
      nextState.spinner = action.spinner;
      nextState.carouselPlaceholder = action.carouselPlaceholder;
      nextState.contentBlocks = action.contentBlocks;
      nextState.isGamePage = action.isGamePage;
      nextState.isGameCategoryPage = action.isGameCategoryPage;
      nextState.bootPageId = action.bootPageId;
      return { ...state, ...nextState };
    case APP_UPDATE_PAGE_ID: {
      const { isGamePage, isGameCategoryPage, pathname } = action;
      nextState.isGamePage = isGamePage;
      nextState.isGameCategoryPage = isGameCategoryPage;
      nextState.pathname = pathname;
      return { ...state, ...nextState };
    }
    default:
      return state;
  }
};

export default reducer;
