import React from 'react';
import { Link } from 'react-router-dom';

const CategoryItem = ({ subCategoryId, categoryLabel, friendlyURL, selected, englishname = '', pageTitle }) => {
  const clazz = !selected ? 'category_item' : 'category_item selected';

  return (
    <Link to={friendlyURL}>
      <div className={clazz} data-subcategoryid={subCategoryId}>
        <span className="category_item_inner">{pageTitle}</span>
      </div>
    </Link>
  );
};
export default CategoryItem;
