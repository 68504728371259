import React, { PureComponent } from 'react';

class Loader extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { spinner, isVisible } = this.props;
    return (
      <>
        {isVisible && (
          <div className="content_block_placeholder">
            <div className="icon_container">
              <img className="icon icon_spin" src={spinner} alt="loading icon" />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Loader;
