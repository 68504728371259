import { combineReducers } from 'redux';
import app from 'store/app/appReducer';
import copy from 'store/copy/copyReducer';
import filters from 'store/filters/filtersReducer';
import entries from 'store/entries/entriesReducer';
import carousels from 'store/carousels/carouselsReducer';
import feature from 'store/feature/featureReducer';
import ads from 'store/ads/adsReducer';
import relatedItems from 'store/relatedItems/relatedReducer';
import category from 'store/category/categoryReducer';

import React from 'react';

const rootReducer = combineReducers({
  app,
  copy,
  filters,
  entries,
  carousels,
  feature,
  ads,
  relatedItems,
  category,
});

export default rootReducer;
